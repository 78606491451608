
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

.text-light {
	color: var(--color-neutral-lightest);
}

.whitelabel-content {
	min-height: calc(100vh - #{$header-total-height});
}
