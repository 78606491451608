
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	ion-page {
		z-index: 0;
	}
	ion-split-pane {
		ion-menu {
			--width: 100vw;
			--max-width: 400px;
			--background: var(--color-neutral-lighest);
			max-width: var(--max-width) !important;

			@media only screen and (max-width: 1200px) {
				--max-width: 100vw;
			}
		}
	}
