
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.micro-button.button-container {
		display: inline-block;

		width: unset;
		height: 26px;
		min-height: 26px;
		margin-bottom: 0;

		div {
			color: unset
		}
	}
