
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	$circle-border-width: 48px;
	$large-circle-size: 524px;

	$medium-circle-size: 286px;
	$medium-pos-left: 386px;

	$small-circle-size: 127px;
	$small-pos-top: 456px;

	.circles-graphic {
		position: relative;
		width: calc(#{$medium-pos-left} + #{$medium-circle-size}); //674px;
		height: calc(#{$small-pos-top} + #{$small-circle-size});

		.circle {
			position: absolute;
			border-radius: 50%;

			&.large {
				width: $large-circle-size;
				height: $large-circle-size;
				background-color: var(--color-whitelabel-secondary-base);
				top: 0;
				left: 0;
			}
			&.medium {
				background-image: $app-bg;
				$medium-pos-top: 64px;

				display: flex;
				justify-content: center;
				align-items: center;

				width: calc(#{$medium-circle-size} + #{$circle-border-width});
				height: calc(#{$medium-circle-size} + #{$circle-border-width});
				top: calc(#{$medium-pos-top} - #{$circle-border-width});
				left: calc(#{$medium-pos-left} - #{$circle-border-width});

				.inner-circle {
					border-radius: 50%;
					background-color: var(--color-whitelabel-primary-base);
					width: $medium-circle-size;
					height: $medium-circle-size;
				}
			}
			&.small {
				background-image: $app-bg;
				$small-pos-left: 141px;

				display: flex;
				justify-content: center;
				align-items: center;

				width: calc(#{$small-circle-size} + #{$circle-border-width});
				height: calc(#{$small-circle-size} + #{$circle-border-width});
				top: calc(#{$small-pos-top} - #{$circle-border-width});
				left: calc(#{$small-pos-left}  - #{$circle-border-width});

				.inner-circle {
					border-radius: 50%;
					background-color: var(--color-whitelabel-primary-base);
					width: $small-circle-size;
					height: $small-circle-size;
				}
			}
		}
	}
