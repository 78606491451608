
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.odb-more-container {
		display: flex;
		flex-direction: column;
		align-items: center;

		.text {
			color: var(--color-neutral-white);
		}
		.icon-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background-color: var(--color-whitelabel-primary-base);
			border-radius: 50%;
			width: 56px;
			height: 56px;
			cursor: pointer;

			i {
				font-size: 36px;
				color: var(--color-neutral-white);
				margin-top: 4px;
			}
		}

	}
