
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.embedded-content {
		border-radius: 8px;
	}

	.no-preview {
		width: 160px;
		height: 80px;
		padding: 0px 16px;

		color: var(--color-neutral-dark);
		background-color: var(--color-neutral-light);
		border-radius: 8px;
	}
