
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.textarea {
		background-color: white;
		border: $border-light-grey;
		border-radius: 16px;
		overflow: hidden;
		max-width: $width-max-button;
		margin: 0 auto;
		width: 100%;
		min-height: 168px;
		display: flex;
		flex-direction: column;
		padding: 24px 24px 8px 24px;

		textarea {
			border: none;
			outline: none;
			resize: none;
			width: 100%;
			flex-grow: 1;
			padding: 0;
			font-family: var(--app-font-regular), serif;
			font-size: 15px;
			color: $color-neutral-darker;
			line-height: 1.6;
		}

		.bottom-bar {
			display: flex;
			margin-top: 10px;
			width: 100%;
			align-items: flex-end;
			justify-content: flex-end;

			.character-count {
				font-size: 14px;
				color: $color-neutral-dark-grey;
				font-family: var(--app-font-regular), serif;

				.count {
					margin-left: 5px;
					color: $color-neutral-darker;
					font-family: var(--app-font-bold), serif;

					&.countMax {
						color: $color-green-base;
					}
				}
			}

			.send-button {
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $color-neutral-light;
				color: white;
				border-radius: 50%;
				width: 32px;
				height: 32px;
				margin-right: -14px;

				&.active {
					background-color: var(--color-primary-base);
					cursor: pointer;
				}

				i {
					margin-left: 2px;
					margin-top: 2px;
				}
			}
		}
	}
