
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.waiting-room-overlay {
		@import "../patient_user/telehealth/scss/TeleHealthVideoControls";

		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: 100%;

		position: fixed;
		top: 0;
		left: 0;
		z-index: 900000;

		background-repeat: no-repeat;
		background-size: cover;
		background-color: var(--color-primary-darker); // in case image doesn't load

		.video-controls {
			.control-button-container {
				.control-button {
					&.disabled {
						background-color: transparent;
						border: 2px solid $color-neutral-dark;

						i {
							color: $color-neutral-dark;
						}
					}
				}
			}
		}

		.call-ended-message {
			color: var(--color-neutral-white)
		}
	}
