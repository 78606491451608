.book-content {
	.book-content-text {
		margin-bottom: 1.5em;
	}

	.button-separator {
		text-align: center;
		font-style: italic;
		color: #5d5d5d;
		margin: 65px 0;
	}

}