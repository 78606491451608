<template>
	<PageWrapper v-if="controller">
		<template #content-header>
			<AppHeader :title="headerTitle" @close="closeButtonOpts.handler" />
		</template>
		<template #content-top>
			<div class="logo-wrapper">
				<ClinicLogo :clinic="clinic"></ClinicLogo>
			</div>
			<ProfileInputFields
					includeSectionHeaders
					includeFieldLabels
					@profileChange="onProfileChange"
					:controller="controller"
					:inputIds="profileFields"
					:requiredInputIds="profileFields"/>
			<LoaderButton :pattern="ButtonColorPattern.FILL"
			              :color="ButtonColor.PRIMARY"
			              :disabled="!allFieldsValid"
			              :loading="false"
			              @click="handleClinicConnectionRequest">
				{{this.$mhat("RequiredProfileModal.UpdateInfoButtonText")}}
			</LoaderButton>
			<RouteButton v-if="isDependent"
			             :route="{name: Route.Home}">
				{{this.$mhat("RequiredProfileModal.CancelButtonText")}}
			</RouteButton>
		</template>
	</PageWrapper>
</template>

<script lang="ts">
	import ProfileModalController from "./ProfileModal.controller";
	import {Options, Vue} from "vue-property-decorator";
	import ProfileInputFields from "@/views/patient_user/profile/components/ProfileInputFields.vue";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import Services from "@/lib/services/Services";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import {defaultToastOpts, presentIonLoader} from "@/lib/utils/helpers/ionic";
	import {PatientProfileField} from "@/lib/types/Profile";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import NotificationService from "@/components/Notification/NotificationService";
	import {ErrorCode, ProvinceCode} from "@/open_api/generated";
	import ClinicLogo from "@/views/patient_user/clinic/components/ClinicLogo.vue";
	import AppHeader from "@/components/Header/AppHeader.vue";
	import {alertController, toastController} from "@ionic/vue";

	@Options({components: {ProfileInputFields, ClinicLogo, AppHeader}})
	export default class RequiredProfileModal extends ProfileModalController
	{
		clinic: ClinicProfile = null;

		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		public async onCreated()
		{
			const invalidProvinceCode = !!this.$route.query.invalidProvinceCode as boolean;

			if (invalidProvinceCode)
			{
				this.controller.userProfile.healthCareProvinceCode = null;
				await this.fetchClinic();

				WebNotification.$emit({
					event: NotifyEvent.Generic,
					type: NotificationType.Swipe,
					severity: NotificationSeverity.Warning,
					title: this.$mhat("RequiredProfileModal.UnsupportedProvinceErrorTitle"),
					message: this.$mhat("RequiredProfileModal.UnsupportedProvinceErrorMessage"),
				});
			}
			else
			{
				await this.fetchClinic();
				await this.handleClinicConnectionRequest();
			}
			await this.dismissLoader();
		}

		public onProfileChange(updatedField: {id: PatientProfileField, value: string})
		{
			if (this.$isCloudMd)
			{
				if (updatedField.id === PatientProfileField.HEALTHCARE_PROVINCE_CODE)
				{
					presentIonLoader.call(this, this.fetchClinic);
				}
			}
		}

		private async fetchClinic()
		{
			const provinceCode = this.controller.userProfile.healthCareProvinceCode as ProvinceCode;
			const clinicId = this.$route.query.clinicId as string;

			if (this.$isCloudMd)
			{
				if (this.isDependent)
				{
					this.clinic = await Services.DependentClinics(this.userProfile.id).getCloudMdClinic(true, provinceCode);
				}
				else
				{
					this.clinic = await Services.PatientClinics.getCloudMdClinic(true, provinceCode);
				}
			}
			else
			{
				if (this.isDependent)
				{
					this.clinic = await Services.DependentClinics(this.userProfile.id).getDependentClinicInfo(clinicId, true);
				}
				else
				{
					this.clinic = await Services.PatientClinics.getClinic(clinicId, true);
				}
			}

			this.profileFields = this.clinic.requiredFields;
		}

		public async handleClinicConnectionRequest()
		{
			try
			{
				await this.presentLoader();
				await this.submitProfile();

				try
				{
					await this.connectToClinic();
					await this.handleClinicStatus();
				}
				catch (error)
				{
					await this.dismissLoader();

					if (error.is(ErrorCode.EligibilityCheckFailed))
					{
						NotificationService.notify({
							event: NotifyEvent.Generic,
							type: NotificationType.Dismiss,
							severity: NotificationSeverity.Reminder,
							title: this.$mhat("RequiredProfileModal.IneligibleHealthCardErrorTitle"),
							message: `${error.message}`,
						});
					}
					else if (!error || !error.is(ErrorCode.IncompleteProfile))
					{
						NotificationService.notify({
							event: NotifyEvent.Generic,
							type: NotificationType.Dismiss,
							severity: NotificationSeverity.Critical,
							title: this.$mhat("RequiredProfileModal.GenericErrorTitle"),
							message: `${error.message}`,
						});
					}
				}
			}
			catch (e)
			{
				await this.dismissLoader();
			}
		}

		private async handleClinicStatus()
		{
			await this.dismissLoader();

			// if connected, route to home page
			if (this.clinic.patientCanBook)
			{
				await toastController.create(
					Object.assign(defaultToastOpts, {message: this.$mhat("RequiredProfileModal.ConnectedToClinicMessage")}));

				this.$mhaRouterPush(this.Route.Home);
			}
			else if (this.clinic.patientBookingPending)
			{
				this.handleNoRecord();
			}
			else
			{
				this.handleBookingUnavailable();
			}
		}

		private async handleNoRecord()
		{
			alertController.create({
				header: this.$mhat("RequiredProfileModal.NoRecordHeaderText"),
				message: this.$mhat("RequiredProfileModal.NoRecordMessageText", {clinicName: this.clinic.name}),
				buttons: [this.$mhat("RequiredProfileModal.EditProfileButtonText"),
					{
						text: this.$mhat("RequiredProfileModal.DismissButtonText"),
						handler: this.routeFromModal.bind(this),
					},
				],
			})
				.then((a) => a.present());
		}

		private async handleBookingUnavailable()
		{
			alertController.create({
				header: this.$mhat("RequiredProfileModal.BookingUnavailableHeaderText"),
				message: this.$mhat("RequiredProfileModal.BookingUnavailableMessageText", {clinicName: this.clinic.name}),
				buttons: [this.$mhat("RequiredProfileModal.EditProfileButtonText"),
					{
						text: this.$mhat("RequiredProfileModal.DismissButtonText"),
						handler: this.routeFromModal.bind(this),
					},
				],
			})
				.then((a) => a.present());
		}

		private async handleCompleteProfile()
		{
			let message = this.$mhat("RequiredProfileModal.CreatePatientRecordMessage", {clinicName: this.clinic.name});

			if (this.isDependent)
			{
				const {firstName} = this.userProfile;
				message = this.$mhat("RequiredProfileModal.CreateDependentPatientRecordMessage",
					{clinicName: this.clinic.name, dependentFirstName: firstName});
			}

			alertController.create({
				header: this.$mhat("RequiredProfileModal.ConnectToClinicHeaderText", {clinicName: this.clinic.name}),
				message,
				buttons: [this.$mhat("RequiredProfileModal.ViewProfileButtonText"), {
					text: this.$mhat("RequiredProfileModal.ConnectButtonText"),
					handler: this.handleClinicConnectionRequest.bind(this),
				}],
			})
				.then((a) => a.present());
		}

		private async handleIncompleteProfile()
		{
			let message = this.$mhat("RequiredProfileModal.IncompleteProfileMessage", {clinicName: this.clinic.name});

			if (this.isDependent)
			{
				const {firstName} = this.userProfile;
				message = this.$mhat("RequiredProfileModal.DependentIncompleteProfileMessage",
					{clinicName: this.clinic.name, dependentFirstName: firstName});
			}

			alertController
				.create({
					header: this.$mhat("RequiredProfileModal.IncompleteProfileHeaderText"),
					message,
					buttons: [this.$mhat("RequiredProfileModal.DismissButtonText")],
				})
				.then((a) => a.present());
		}

		private routeFromModal()
		{
			if (this.isDependent)
			{
				this.$mhaRouterPush({
					name: this.Route.Dependents.ViewDependent,
					params: {
						dependentId: this.userProfile.id,
					},
				});
			}
			else
			{
				this.$mhaRouterPush(this.Route.Home);
			}
		}

		private async connectToClinic()
		{
			const clinicId = this.clinic.id;
			const connectionRequest = {
				patient_request: {
					patient_registered: true,
					patient_connect_message: this.connectionMessage,
				},
			};

			if (this.isDependent)
			{
				this.clinic = await Services.DependentClinics(this.userProfile.id).connectToClinic(this.clinic.id);
			}
			else
			{
				this.clinic = await Services.PatientClinics.connectToClinic(clinicId, connectionRequest);
			}
		}

		get connectionMessage(): string
		{
			let message = this.$mhat("RequiredProfileModal.ConnectionMessageText");

			if (this.isDependent)
			{
				const {firstName, lastName} = this.userProfile;
				message = this.$mhat("RequiredProfileModal.DependentConnectionMessageText",
					{dependentFirstName: firstName, dependentLastName: lastName});
			}

			return message;
		}

		get headerTitle()
		{
			return this.isDependent
				? this.$mhat("RequiredProfileModal.DependentHeaderTitle")
				: this.$mhat("RequiredProfileModal.PatientHeaderTitle");
		}
	}
</script>

<style scoped>
	.logo-wrapper {
		display: flex;
		justify-content: center;
	}
</style>
