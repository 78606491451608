.booked-status {
	padding-top: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	padding-top: 32px;

	.booked-status {
		margin: 16px 0;
	}

	span {
		margin-bottom: 16px;
		font-family: var(--app-font-regular), serif;
	}
}
.full-width {
	width: 100%;
}
