
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	@media only screen and (min-width: 1200px) {
		#chat-toggle {
			display: none;
		}
	}

	$transition-time: 0.25s;

	.fade-enter, .fade-leave-to {
		opacity: 0;
	}

	.fade-enter-active, .fade-leave-active {
		transition: all $transition-time ease-in;
	}

	.overlay-content-wrapper {
		z-index: 1;
	}
