
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.clinic-provider-info {
		font-family: var(--app-font-regular), sans-serif;
		text-align: left;
		line-height: 1.4;
		color: $color-neutral-darker;
		height: fit-content;
		width: 100%;

		.info-header {
			font-size: 18px;
			font-weight: bold;
		}
		.info-body {
			font-size: 17px;
		}

		.provider-swipe-container {
			display: flex;
			flex-direction: row;

			.provider-list-wrapper {
				display: flex;
				flex-direction: column;
				min-width: 240px;

				.provider-list {
					text-align: left;
					width: 100%;
					margin: 0;
				}

				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
				}
			}

		}
	}

