
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	$border-width: 7px;

	.device-frame {
		background-color: var(--color-neutral-white);
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&.no-border {
			background-color: transparent;
		}

		&.dark {
			background-color: $color-primary-darkest;
		}

		&.phone {
			width: 194px;
			height: 414px;
			border-radius: 24px;
		}
		&.tablet {
			width: 610px;
			height: 455px;
			border-radius: 24px;
		}
		&.laptop {
			$border-width: 40px;
			$laptop-width: 772px;
			$keyboard-width: 949px;
			background-color: var(--color-neutral-lightest);

			width: $laptop-width;
			height: 525px;
			border-radius: 38px 38px 0 0;
			.screen {
				border-radius: 0;
				width: calc(100% - (#{$border-width} * 2));
				height: calc(100% - (#{$border-width} * 2));
			}
			.keyboard {
				position: absolute;
				bottom: -32px;
				left: calc((#{$keyboard-width} - #{$laptop-width}) / 2 * -1);
				.upper-bottom {
					width: $keyboard-width;
					height: 19px;
					background-color: var(--color-neutral-lightest);
					border: 1px solid var(--color-neutral-lighter);
					display: flex;
					justify-content: center;

					.indent {
						background-color: var(--color-neutral-white);
						width: 156px;
						height: 12px;
						margin-top: 3px;
						border-bottom-left-radius: 12px;
						border-bottom-right-radius: 12px;
					}
				}
				.lower-bottom {
					border-top: 13px solid var(--color-neutral-lighter);
					border-left: 35px solid transparent;
					border-right: 35px solid transparent;
				}
			}
		}
		.screen {
			position: relative;
			width: calc(100% - (#{$border-width} * 2));
			height: calc(100% - (#{$border-width} * 2));
			border-radius: 24px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
