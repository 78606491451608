
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.review-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		border-radius: 16px;
		background-color: var(--color-neutral-white);
		box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
		padding: 0 24px;

		.reviewer-image {
			margin-top: 24px;
			border-radius: 50%;
			width: 112px;
			height: 112px;
			&.large {
				margin-top: 32px;
				width: 112px;
				height: 112px;
			}
		}
		.reviewer-name {
			margin-top: 16px;
			color: var(--color-neutral-darker);
			font-size: 24px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.08;
			letter-spacing: normal;
			text-align: center;
		}
		.reviewer-location {
			margin-top: 4px;
			color: var(--color-neutral-darker);
			font-size: 18px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: center;
		}
		.reviewer-message {
			margin-top: 4px;
			color: var(--color-neutral-grey);
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: center;
		}
	}
