
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.whitelabel-layout-root {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		padding: 0 16px;
		margin-bottom: 32px;
		min-height: calc(100vh - #{$header-total-height});

		.whitelabel-layout-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			max-width: $middle-screen-width;

			.logo-constraints {
				max-width: 512px;
			}
			.header-text {
				color: var(--color-whitelabel-primary-base);
				padding-top: 24px;

				font-size: 32px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.05;
				letter-spacing: normal;
				text-align: center;
			}
			.label-text {
				color: var(--color-neutral-white);
				padding: 8px 0 24px 0;

				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
				text-align: center;
			}
			.whitelabel-content {
				width: 100%;
				max-width: calc(#{$mobile-screen-width} - #{$content-horizontal-padding} * 2);
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.whitelabel-layout-root.align-center {
		justify-content: center;
		padding-bottom: $header-total-height;
	}
