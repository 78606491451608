
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.app-badge-container {
		display: inline-block;
		margin: 0 -2px;
		a {
			margin: 0 2px;
			text-decoration: none;
			img {
				width: 186px;
			}
		}
	}
