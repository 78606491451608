
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	/deep/ .clinic-disconnect {
		font-family: var(--app-font-regular), sans-serif;
		text-align: center;
		font-size: 17px;
		color: $color-red-base;
		line-height: 1.41;
		height: 128px;
	}
	.disconnect-button-container {
		max-width: $width-max-button;
		margin: 0 auto;
	}
