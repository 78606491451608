
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.telehealth-audio-embedded {

		.call-button .icon,.mute-button .icon {
			font-size: 2em;
		}

		.call-state-text {
			max-width: 110px;
		}

		.call-indicator {
			&.calling {
				--pulse-color: var(--color-orange-base);
			}
			&.connected {
				--pulse-color: var(--color-lime-base);
			}
			&.not-connected {
				--pulse-color: var(--color-red-base);
			}
		}
	}
