@import "~@/assets/styles/_colorPalette.scss";

@mixin font ($color, $size, $line-height, $font-weight: normal, $font-family: var(--app-font-regular)) {
	font-family: $font-family, serif;
	font-size: $size;
	font-weight: $font-weight;
	font-stretch: normal;
	font-style: normal;
	line-height: $line-height;
	letter-spacing: normal;
	color: $color;
}

// Header font style mixins

@mixin mha-font-h0($color: unset) {
	@include font($color, 40px, 1.05, bold, var(--app-font-bold))
}
@mixin mha-font-h1($color: unset) {
	@include font($color, 32px, 1.06, bold, var(--app-font-bold))
}
@mixin mha-font-h2($color: unset) {
	@include font($color, 28px, 1.07, bold, var(--app-font-bold))
}
@mixin mha-font-h3($color: unset) {
	@include font($color, 24px, 1.06, bold, var(--app-font-bold))
}
@mixin mha-font-h4($color: unset) {
	@include font($color, 22px, 1.18, bold, var(--app-font-bold))
}
@mixin mha-font-h5($color: unset) {
	@include font($color, 20px, 1.2, bold, var(--app-font-bold))
}
@mixin mha-font-h6($color: unset) {
	@include font($color, 18px, 1.33, normal, var(--app-font-bold))
}

// body/text font style mixins
@mixin mha-font-body-xl($color: unset) {
	@include mha-font-body-md($color); // TODO
}
@mixin mha-font-body-lg($color: unset) {
	@include font($color, var(--font-body-lg), 1.5);
}
@mixin mha-font-body-md($color: unset) {
	@include font($color, var(--font-body-md), 1.5);
}
@mixin mha-font-body-sm($color: unset) {
	@include font($color, var(--font-body-sm), 1.14);
}
@mixin mha-font-body-xs($color: unset) {
	@include font($color, var(--font-body-xs), 1.17);
}

@mixin mha-font-defaults($color) {

	// Header font
	@at-root {
		h1#{&} {
			@include mha-font-h1($color);
		}
		h2#{&} {
			@include mha-font-h2($color);
		}
		h3#{&} {
			@include mha-font-h3($color);
		}
		h4#{&} {
			@include mha-font-h4($color);
		}
		h5#{&} {
			@include mha-font-h5($color);
		}
		h6#{&} {
			@include mha-font-h6($color);
		}
		p#{&} {
			@include mha-font-body-md($color);
		}
	}

	h1 {
		@include mha-font-h1($color);
	}
	h2 {
		@include mha-font-h2($color);
	}
	h3 {
		@include mha-font-h3($color);
	}
	h4 {
		@include mha-font-h4($color);
	}
	h5 {
		@include mha-font-h5($color);
	}
	h6 {
		@include mha-font-h6($color);
	}
	p {
		@include mha-font-body-md($color);
	}

	// Body font
	&.mha-font-body-xl,
	.mha-font-body-xl {
		@include mha-font-body-xl($color);
	}

	&.mha-font-body-lg,
	.mha-font-body-lg {
		@include mha-font-body-lg($color);
	}

	&.mha-font-body-md,
	.mha-font-body-md,
	{
		@include mha-font-body-md($color);
	}

	&.mha-font-body-sm,
	.mha-font-body-sm {
		@include mha-font-body-sm($color);
	}

	&.mha-font-body-xs,
	.mha-font-body-xs {
		@include mha-font-body-xs($color);
	}
}


// default font styling as outlined by https://zpl.io/V0QYqmm
@mixin default-font-style {
	.font-style-light {
		@include mha-font-defaults(var(--color-neutral-darker));
	}
	.font-style-grey {
		@include mha-font-defaults(var(--color-neutral-grey));
	}
	.font-style-dark {
		@include mha-font-defaults(var(--color-neutral-white));
	}
	.font-style-primary {
		@include mha-font-defaults(var(--color-primary-base));
	}
}

:root {
	// font size variables
	--font-body-lg: 18px;
	--font-body-md: 16px;
	--font-body-sm: 14px;
	--font-body-xs: 12px;

	// Font colors
	.font-color-darker {
		color: var(--color-neutral-darker);
	}

	.font-color-neutral-dark-grey {
		color: var(--color-neutral-dark-grey);
	}

	.font-color-neutral-grey {
		color: var(--color-neutral-grey);
	}

	.font-color-neutral-white {
		color: var(--color-neutral-white);
	}

	.font-color-primary-base {
		color: var(--color-primary-base);
	}

	.font-color-line-base {
		color: var(--color-lime-base);
	}

	.font-color-red-base {
		color: var(--color-red-base);
	}
}
