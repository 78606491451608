
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.kiosk-wrapper {
		height: 100vh;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.kiosk-content {
			margin: auto;
			min-height: 100vh;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			.header {
				max-width: 100vw;
				margin-bottom: auto;
				max-height: 104px;
				flex: 1 1 auto;
				overflow: hidden;
			}

			.body {
				// set dynamically in onContentChange()
				max-height: 800px;
				min-height: 800px;

				overflow: hidden;

				.inner-body {
					display: flex;
					flex-direction: column;
					align-items: center;

					.content {
						max-width: 544px;
					}
				}
			}

			.footer {
				margin-top: auto;
				padding: 0 16px 16px 16px;

				max-width: 100vw;
				max-height: 240px;

				flex: 1 1 auto;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				overflow: hidden;
			}
		}
	}
