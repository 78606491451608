
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.button-list-wrapper {
		width: 100%;

		ion-list {
			--ion-item-background: none;
			ion-item {
				--ion-item-background: #{$color-neutral-white};
				&:hover {
					--ion-item-background: #{$color-neutral-lighter};
					cursor: pointer;
				}
				.primary {
					color: var(--color-primary-base);
				}
				.info {
					color: $semantic-info-base;
				}
				.warning {
					color: $color-warning-button;
				}
				.danger {
					color: $color-critical-button;
				}
			}
		}
	}
