
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.message-wrapper {

		display: flex;
		flex-direction: column;

		.message-item {
			font-family: var(--app-font-regular), serif;
			width: 100%;
			height: 100%;
			min-height: 104px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			&.clinic {
			}
			&.subject {
				.basic-input {
					padding: 0;
				}
				input {
					width: 100%;
				}
			}
			&.body {
			}
			&.attachments {
				&.hide-attachments {
					display: none;
				}
				.list-ios {
					margin-bottom: 0;
				}
			}

			.header {
				@include mha-font-body-sm(var(--color-neutral-grey));
				display: inline-block;
				padding-bottom: 8px;
				&.compose {
					padding-left: 16px;
				}
			}
			.message-content-container {
				display: inline-block;

				textarea::-webkit-input-placeholder,
				textarea:-ms-input-placeholder,
				textarea::placeholder{
					color: var(--color-neutral-dark);
					opacity: 0.5;
				}

				.content {
					@include mha-font-body-lg;
					padding: 0;
					margin: 0;
				}
			}
		}
	}
