
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.waiting-room-overlay {
		width: 100%;
		height: 100%;

		position: fixed;
		top: 0;
		left: 0;
		z-index: 900000;
	}
