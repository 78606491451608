
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.z-position-container {
		margin: 0 auto;
		padding: 0;
		max-width: $full-screen-width;
		position: relative;
		height: 100%;
		min-height: 100%;

		.background-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;

			&.hide-overflow {
				height: 100%;
				overflow: hidden;
			}
		}
	}
