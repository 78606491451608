
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.info-header {
		background-image: none;
	}

	.top-content {
		position: relative;

		.clinic-info {
			height: 100%;
			display: flex;
			flex-direction: column;
			font-family: var(--app-font-regular), serif;

			.info-top {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.name {
				font-family: var(--app-font-bold), serif;
				font-size: 24px;
			}

			.street, .city {
				font-size: $default-font-size;
				line-height: 26px;
			}

			.phone {
				margin-top: 20px;
				margin-bottom: 0;
			}
		}
	}

	.bottom-content {
		margin: 0 auto;

		.info-card {
			margin: 32px 0;
		}
		.telehealth-button {
			margin: -16px auto 26px 0;

			@media only screen and (min-width: 768px)
			{
				max-width: 344px; // same as appt card
			}
		}

		.reschedule-button {
			margin: -16px auto 16px 0;

			@media only screen and (min-width: 768px)
			{
				max-width: 344px; // same as appt card
			}
		}

		.action-btns {
			margin-bottom: 40px;
		}

		.notes {
			margin: 0 auto;
			line-height: 1.65;

			h3 {
				font-family: var(--app-font-bold), serif;
				font-weight: unset;
				margin-top: 0px;
				margin-bottom: 8px;
			}

			p {
				margin: 0;
				white-space: pre-wrap;
			}
		}
	}

