
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.header-text {
		text-align: center;
	}

	.body-text {
		margin: 32px 0 32px 0;
	}
