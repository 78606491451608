
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.subheader_with_colour {
		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;

		padding-bottom: 16px;

		.circle {
			flex: 0 0 16px;
			border-radius: 50%;
			width: 16px;
			height: 16px;
			margin-right: 16px;
			background-color: var(--color-primary-base);
		}
	}
