
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	ion-fab-button {

		width: 64px;
		height: 64px;
		&.small {
			width: 32px;
			height: 32px;
		}
		&.x-small {
			width: 16px;
			height: 16px;
		}

		&.no-box-shadow {
			--box-shadow: none;
		}

		//#### Style Options ###

		&.transparent {
			--border-style: solid;
			--border-width: 2px;
			--border-color: white;
			--border-radius: 100%;

			--background: rgba(var(--color-neutral-darkest-alpha), 0.3);
			--background-activated: rgba(var(--color-neutral-darkest-alpha), 0.3);
			--background-hover: rgba(var(--color-neutral-darkest-alpha), 0.5);

			&.disabled {
				opacity: 0.3;
				--background: rgba(var(--color-neutral-darkest-alpha), 0.3);
				--background-hover: rgba(var(--color-neutral-darkest-alpha), 0.3);
				--border-color: white;
				--color: inherit;
			}

			&.toggle-on {
				--background: white;
				--background-activated: white;
				--background-hover: white;
				--color: var(--color-primary-base);

				i {
					color: var(--color);
				}
			}
		}

		&.fill {
			--border-width: 0;

			&.danger {
				--background: var(--color-red-base);
				--background-hover: var(--color-red-base);
				--background-activated: var(--color-red-darker);

				&.disabled {
					opacity: 30%;
				}
			}

			&.success {
				--background: var(--color-primary-base);
				--background-hover: var(--color-primary-base);
				--background-activated: var(--color-primary-darker);

				&.disabled {
					opacity: 30%;
				}
			}

			&.greyscale-dark {
				--background: var(--color-neutral-darker);
				--background-hover: var(--color-neutral-darkest);
				--background-activated: var(--color-neutral-darkest);
			}
		}

		--border-style: solid;
		--border-width: 2px;
		--border-radius: 100%;
		--border-color: var(--color-neutral-darkest);

		&.disabled {
			--background: var(--color-neutral-white);
			--color: var(--color-neutral-grey);
			--border-color: var(--color-neutral-grey);
			opacity: 1.0;
		}
	}
