
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.separator.text.mha {
		margin-top: 0;
	}

	.show-all-button
	{
		margin-top: 32px;
	}
