
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	/deep/ .header-container {
		height: 64px;
		align-items: center;
		padding-bottom: 0;
	}

	.status-chip {
		--background: none;
		--color: var(--color-orange-base);

		transition: all 0.3s ease;
		padding-right: 32px;
		position: relative;

		ion-spinner {
			transition: opacity 0.3s ease;
			position: absolute;
			right: 0;
			opacity: 1;
		}
	}

	.connected {
		.status-chip {
			--color: var(--color-primary-base);
			padding-right: 12px;

			ion-spinner {
				opacity: 0;
			}
		}
	}

	@media only screen and (max-width: 768px) {
		/deep/ .header-container {
			padding: 0 16px;
		}
	}
