
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.image-container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 100%;
		height: auto;
		background-color: var(--color-mha-primary-darkest);

		.background-image-container {
			width: 1920px;
			height: 1080px;
			overflow: hidden;

			&.center-background {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
