
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	$content-max-width: 444px;

	@mixin banner-item-color-scheme($primary-color, $secondary-color, $icon-color, $icon-bg-color) {
		background-color: $primary-color;
		color: $secondary-color;

		.banner-icon {
			background-color: $icon-bg-color;
			i {
				color: $icon-color;
			}
		}
	}

	.banner-notification-item {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		width: 100% !important; // important to override ionic-overrides 90%

		/* notch correction for iPhone x models */
		@include safe-area-padding-top;

		@include banner-item-color-scheme(var(--color-mha-primary-darkest), var(--color-neutral-white),
				var(--color-neutral-white), var(--color-mha-primary-darkest));

		&.whitelabel {
			@include banner-item-color-scheme(var(--color-whitelabel-primary-darkest), var(--color-neutral-white),
				var(--color-neutral-white), var(--color-whitelabel-primary-darkest));
		}

		.notification-container {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-end;
			overflow: auto;
			min-height: 56px;

			.notification-header {
				position: relative;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;

				max-width: $content-max-width;
				flex: 1 1 auto;
				padding: 16px;
				@media only screen and (max-height: 667px) {
					padding: 4px 16px;
				}

				&.has-buttons {
					padding-bottom: 0;
				}

				$icon-size: 40px;
				.banner-icon {
					width: $icon-size;
					height: $icon-size;
					min-width: $icon-size;
					min-height: $icon-size;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;

					i {
						font-size: 32px;
					}
				}
				.banner-text {
					overflow: hidden;
					margin-left: 16px;
					text-align: left;

					font-size: 14px;
					h5 {
						color: var(--color-neutral-lightest);
						font-size: 18px;
						font-weight: bold;
					}
				}
			}
		}

		.buttons-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 72px;
			padding: 16px 0;

			.button-content {
				flex: 1 1 $content-max-width;
				max-width: $content-max-width;
				padding: 0 16px 0 16px;
			}
		}
	}
