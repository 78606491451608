
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.attachment-view-wrapper {
		max-width: 400px;

		.attachment-link {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			cursor: pointer;
			padding-left: 16px;
			padding-right: 16px;
			border-radius: 8px;
			height: 48px;

			&:hover {
				background-color: var(--color-neutral-lighter);
			}

			.item-label {
				font-size: 14px;
				cursor: pointer;
			}

			i {
				color: var(--color-primary-base);

				&.icon-lg {
					font-size: 32px;
				}
			}
		}
	}
