
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	$debug_colours_enabled: false;

	@mixin container-options {
		position: relative;
		display: inline-block;
		width: 100%;
	}

	.padding-horizontal {
		padding-left: $content-horizontal-padding;
		padding-right: $content-horizontal-padding;
	}

	ion-content {
		&.background-disabled {
			--background: none !important;
		}
	}

	.content-wrapper {

		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-grow: 1;
		min-height: 100%;
		margin: auto;
		height: auto;
		width: 100%;

		.content-header-container {
			@include container-options;

			// false height that matches our headers. this keep all header-less content pages vertically aligned.
			min-height: $header-total-height;
			padding-bottom: $header-padding-bottom;

			&.no-header-space {
				min-height: 0;
				padding-bottom: 0;
			}
		}

		.content-body-container {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			//required for safari (to keep password link at page bottom) because it can't handle flex blocks properly
			justify-content: space-between;
			width: 100%;
			margin: 0 auto;

			// different width classes, largest should take precedence
			&.content-width-mobile {
				max-width: 100%;

				@media only screen and (min-width: $mobile-screen-width) {
					max-width: $mobile-screen-width;
				}
			}

			&.content-width-mid {
				max-width: $middle-screen-width;
			}

			&.content-width-full {
				max-width: $full-screen-width;
			}

			.content-body-top-layout-wrapper {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				justify-content: flex-start;

				.content-body-container-top {
					@include container-options;
					@include content-container-top-min-height;
				}

				.content-body-container-center {
					@include container-options;
				}
			}

			.content-body-container-bottom {
				@include container-options;

				&.extra-margin-bottom {
					// add space at bottom to counter the lack of ion-content padding
					margin-bottom: $content-bottom-padding;
				}
			}
		}

		.content-footer-container {
			@include container-options;
		}

		// for smaller screen height
		@media only screen and (max-height: 667px) {
			.content-header-container {
				padding-bottom: calc(#{$header-padding-bottom} / 2);
			}
		}
		// for extra-small screen height
		@media only screen and (max-height: 568px) {
			.content-header-container {
				padding-bottom: calc(#{$header-padding-bottom} / 4);
			}
		}

	}

	@if $debug_colours_enabled {
		.content-wrapper {
			background-color: $color-cyan-lightest;

			.content-header-container {
				background-color: $color-lime-lighter;
			}

			.content-body-container {
				background-color: $color-cyan-lightest;

				.content-body-top-layout-wrapper {
					background-color: $color-red-lightest;

					.content-body-container-top {
						background-color: $color-indigo-lightest;
					}

					.content-body-container-center {
						background-color: $color-violet-lightest;
					}
				}

				.content-body-container-bottom {
					background-color: $color-indigo-lightest;
				}
			}

			.content-footer-container {
				background-color: $color-lime-lighter;
			}
		}
	}
