
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.call-indicator {
		&.connected {
			--pulse-color: var(--color-lime-base);
		}
		&.not-connected {
			--pulse-color: var(--color-red-base);
		}
	}

	@media only screen and (max-width: 786px)
	{
		.spacing {
			height: var(--on-screen-keyboard-default-height);
		}
	}
