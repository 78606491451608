
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.header-title {
		height: 100%;
		width: fit-content;
		display: flex;
		align-items: center;

		img {
			width: 40px;
		}

		span {
			font-family: var(--app-font-bold), serif;
			font-size: 24px;
			color: var(--color-primary-base);
			padding-left: 10px;
		}
	}
