
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	$roundel-size: 120px;
	$roundel-border-size: 8px;
	$telehealth-roundel-size: 48px;

	.logo-container {
		position: relative;
		width: fit-content;
		height: fit-content;

		.clinic-roundel {
			display: flex;
			align-items: center;
			justify-content: center;
			background: var(--app-bg);
			border-radius: 50%;
			overflow: hidden;

			.logo-image {
				height: 100%;
				/*flex: 1 1 100%;*/
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;

				.clinic-default {
					color: var(--color-neutral-white);
					background-color: var(--color-primary-base);
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					width: 100%;
					height: 100%;

					i {
						width: fit-content;
						height: fit-content;
						font-size: 88px;
						z-index: 2;
					}
				}

				img {
					display: block;
					margin-left: auto;
					margin-right: auto;
					object-fit: contain;
				}
			}
		}

		.telehealth-rondel {
			z-index: 2;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			width: $telehealth-roundel-size;
			height: $telehealth-roundel-size;
			background: var(--app-bg);
			border-radius: 50%;
			bottom: 0;
			right: 0;

			.telehealth-inner {
				display: flex;
				align-items: center;
				justify-content: center;
				width: calc(#{$telehealth-roundel-size} - #{$roundel-border-size} * 2);
				height: calc(#{$telehealth-roundel-size} - #{$roundel-border-size} * 2);
				background: var(--color-primary-base);
				border-radius: inherit;

				i {
					color: $color-neutral-white;
					font-size: 24px;
					z-index: 2;
				}
			}
		}
	}
