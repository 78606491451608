
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.toggle {

		ion-toggle {
			--background-checked: var(--color-whitelabel-primary-base);

			&.md {
				--handle-background-checked: white;
			}

			&.ios {
				margin-right: 8px;
			}
		}

		label {
			font-size: 15px;
			font-family: var(--app-font-regular), serif;
			color: $color-neutral-darker;
			font-weight: normal;
			margin-left: 8px;
		}

		.toggle-container {
			cursor: pointer;
			position: relative;
			display: flex;
		}

		.track {
			width: 72px;
			height: 36px;
			background-color: white;
			border-radius: 36px;
			border: 1px solid $color-neutral-light;
			overflow: hidden;
			transition: none;

			&.active {
				border-color: var(--color-primary-base);
				background-color: var(--color-primary-base);
				transition: background-color 50ms 0.1s;

				&.disabled {
					border-color: $color-neutral-light;
					background-color: $color-neutral-lighter;
				}
			}

			&.inactive {
				border-color: $color-neutral-light;
			}
		}

		.switch {
			position: absolute;
			width: 36px;
			height: 36px;
			border-radius: 36px;
			border-style: solid;
			border-width: 1px;
			overflow: hidden;
			background-color: $color-neutral-white;
			transition: transform 0.15s ease;

			&.active {
				border-color: var(--color-primary-base);

				transform: translateX(36px);

				&.disabled {
					border-color: $color-neutral-light;
				}
			}

			&.inactive {
				border-color: $color-neutral-light;
				transform: translateX(0);
			}
		}

		.background {
			height: 100%;
			background-color: var(--color-primary-base);
			transition: width 0.15s ease;

			&.active {
				width: calc(100% - 18px);
			}

			&.inactive {
				width: 18px;
			}
		}
	}
