
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.event-state-container {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&.text-color-light {
			h4, p {
				color: var(--color-neutral-lightest);
			}
		}

		h4, p {
			text-align: center;
		}

		.event-state-spinner {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			$event-state-spinner-size: 196px;
			width: $event-state-spinner-size;
			height: $event-state-spinner-size;
			border-radius: 50%;
			margin: 16px 0 24px 0;

			.event-state-icon {
				background-color: var(--color-whitelabel-primary-base);
				width: 136px;
				height: 136px;
				border-radius: 50%;

				display: flex;
				justify-content: center;
				align-items: center;

				$icon-size: 112px;
				.icon-container {
					position: relative;
					width: $icon-size;
					height: $icon-size;

					i, img {
						position: absolute;
						font-size: $icon-size;
						color: var(--color-neutral-white);
						top: 0;
						left: 0;
					}

					img {
						width: $icon-size;
					}
				}
			}
			.spinner {
				$rotation-time: 1.5s;

				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: transparent;
				border-radius: 50%;

				border-left: 8px solid var(--color-whitelabel-primary-base);
				border-right: 8px solid var(--color-whitelabel-primary-base);
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;

				-webkit-animation-name: Rotate;
				-webkit-animation-duration: $rotation-time;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-timing-function: linear;
				-moz-animation-name: Rotate;
				-moz-animation-duration: $rotation-time;
				-moz-animation-iteration-count: infinite;
				-moz-animation-timing-function: linear;
				-ms-animation-name: Rotate;
				-ms-animation-duration: $rotation-time;
				-ms-animation-iteration-count: infinite;
				-ms-animation-timing-function: linear;
			}

			@-webkit-keyframes Rotate {
				from {
					-webkit-transform: rotate(0deg) translateZ(0);
				}
				to {
					-webkit-transform: rotate(360deg) translateZ(0);
				}
			}
			@-moz-keyframes Rotate {
				from {
					-moz-transform: rotate(0deg) translateZ(0);
				}
				to {
					-moz-transform: rotate(360deg) translateZ(0);
				}
			}
			@-ms-keyframes Rotate {
				from {
					-ms-transform: rotate(0deg) translateZ(0);
				}
				to {
					-ms-transform: rotate(360deg) translateZ(0);
				}
			}
		}
	}
