
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.see-doctor-button-wrapper {
		width: 344px;
	}
	.more-spacing {
		position: absolute;
		bottom: 144px;
		@media only screen and (max-height: 1024px) {
			top: 65vh;
			bottom: unset;
		}
		@media only screen and (max-height: 720px) {
			top: 60vh;
		}
		@media only screen and (max-height: 530px) {
			display: none;
		}
	}
	.care-cards-wrapper {
		margin: 128px auto 72px auto;
		max-width: 1100px;
	}
	.how-can-we-help-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	.middle-image-wrapper {
		margin-top: -256px;

		@media only screen and (max-width: 1024px) {
			display: none;
		}
	}
	.reviews-wrapper {
		margin-top: -204px;
		padding-bottom: 140px;

		@media only screen and (max-width: 1024px) {
			margin-top: 64px;
		}
	}
	.app-download-wrapper {
		padding: 160px 0 180px 0;

		@media only screen and (max-width: 965px) {
			padding: 120px 0 100px 0;
		}
	}
