:root {
	--app-bg: var(--color-neutral-lightest);

	// height of input and select fields
	--input-field-height: 56px;

	// default height of buttons
	--base-button-height: 56px;

	// ionic default on screen keyboard height
	--on-screen-keyboard-default-height: 290px;
}

@import "./colorPalette";
@import "./mixins/font_styles";

$border-light-grey: 1px solid rgba(124, 129, 140, 0.34);
$border-primary-dark: 1px solid var(--color-primary-dark);

$border-radius-default: 8px;

// Widths
$width-max: 1024px;
$width-max-medium: 768px;
$width-max-small: 328px;

$width-max-button: 400px;
$width-min-small-button: 160px;
$width-max-app: 1440px;

$height-max-app: 1024px;

$width-main: 100%;

$mobile-screen-width: 400px;
$middle-screen-width: 960px;
$full-screen-width: 1920px;

// Component Sizes
$height-header: 80px;
$height-footer-mobile: 72px;
$height-footer-desktop: 72px;

$header-padding-bottom: 8px;
$header-total-height: calc(#{$height-header} + #{$header-padding-bottom});
$content-bottom-padding: 16px; // space at the bottom of the content container
$content-horizontal-padding: 16px; // space at the left/right of the content container

$width-card: 344px;
$width-split-feed: 392px;

// Z-Indices
$layer-top: 998;
$layer-module: 1;

// Transitions
$transition-button-fill: 0.15s cubic-bezier(.25,.8,.5,1);

// Shadows
$box-shadow-default: 0 4px 10px -2px rgba(10, 24, 51, 0.3);
$box-shadow-base-button: 0 2px 5px -1px rgba(10, 24, 51, 0.1);
$box-shadow-floating-action: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
$box-shadow-dropdown: 5px 5px 19px -9px rgba(0, 0, 0, 0.35);
$box-shadow-inset: inset 0 0 12px -5px rgba(0, 0, 0, 0.9);

// Line Height
$line-height-default: 1.6;

// Font Sizes
$default-font-size: 16px;
$default-font-size-pill: 15px;
$default-font-size-small: 14px;
$default-letter-spacing: 0.0px;

// Assets
$app-bg: url("~@/assets/images/app-background.png");
