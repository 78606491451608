
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	@mixin footer-color-scheme($background, $logo, $title-text, $content-text, $link-hover) {
		background-color: $background;
		color: $content-text;

		.link {
			a {
				color: $content-text;
				&:hover {
					color: $link-hover;
					cursor: pointer;
				}
			}
		}
		.corporate {
			color: $title-text;
			.logo {
				color: $logo;
			}
		}
	}

	.footer {
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 48px;
		width: 100%;
		background-color: var(--color-neutral-lightest);

		@include mha-font-body-xs(var(--color-corporate-primary-darkest));
		@include footer-color-scheme(var(--color-neutral-lightest),
				var(--color-neutral-white),
				var(--color-corporate-primary-darkest),
				var(--color-corporate-primary-darkest),
				var(--color-corporate-primary-base)
		);

		&.stick {
			position: relative;
		}
		&.branded:not(.no-brand-bg) {
			@include footer-color-scheme(var(--color-corporate-primary-base),
					var(--color-neutral-white),
					var(--color-neutral-darker),
					var(--color-neutral-darker),
					var(--color-corporate-primary-darkest)
			);
			height: 200px;
		}
		&.branded.brand-white-bg {
			@include footer-color-scheme(var(--color-neutral-white),
				var(--color-corporate-primary-base),
				var(--color-neutral-darker),
				var(--color-neutral-darker),
				var(--color-corporate-primary-darkest)
			);
			height: 200px;
		}
		&.branded.brand-grey-bg {
			@include footer-color-scheme(var(--color-neutral-lightest),
				var(--color-neutral-darkest),
				var(--color-neutral-darker),
				var(--color-neutral-darker),
				var(--color-corporate-primary-darkest)
			);
			height: 200px;
		}

		.horizontal-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: center;
			justify-content: center;
			padding: 2px 0;
		}

		.separator {
			margin: 0 8px;
		}

		.link {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			a:hover {
				text-decoration: underline;
			}
		}

		.corporate {
			display: flex;
			align-items: center;
			@include mha-font-body-lg;
			.logo {
				font-size: 32px;
			}
		}

		.small-text {
			font-size: 11px;
		}

		.text-align-right {
			text-align: right;
		}
	}
