@import "./variables";
@import "./modals";
@import "./popover";
@import "layout";
@import "transitions/transitions";

// Styles

p {
	padding-left: 8px;
	padding-right: 8px;

	margin-top: 8px;
	margin-bottom: 16px;
	line-height: 1.4;
}

* {
	@include scrollbar;
}

.mha {
	&.module {
		width: $width-main;
		height: 100%;
		-webkit-overflow-scrolling: touch;
		margin: 0 auto;
		flex-grow: 1;
		flex-basis: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		overflow-y: auto;
		overflow-x: hidden;

		&.full {
			height: 100vh;
			overflow: hidden;
		}
	}

	&.content {
		width: 87%;
		margin: 0 auto;
	}

	&.scrollbar {
		@include scrollbar;
	}

	&.text {
		color: $color-neutral-darker;
		font-size: 16px;

		&.regular-font {
			font-family: var(--app-font-regular), serif;
		}

		&.italic-font {
			font-family: var(--app-font-italic), serif;
		}

		&.bold-font {
			font-family: var(--app-font-bold), serif;
		}

		&.black-font {
			font-family: var(--app-font-bold), serif;
		}


		&.body {
			font-family: var(--app-font-regular), serif;
			line-height: $line-height-default;
			width: 100%;
			letter-spacing: $default-letter-spacing;
			font-size: $default-font-size;
			white-space: pre-line;

			.bold {
				font-family: var(--app-font-bold), serif;
			}
		}

		&.helper {
			margin: 0 auto;
			line-height: $line-height-default;
			padding-bottom: 16px;
			width: 100%;
			max-width: $width-max-button;
			text-align: center;

			.bold {
				font-family: var(--app-font-bold), serif;
			}

			&.medium {
				padding-bottom: 24px;
			}
		}

		&.segment {
			width: 95%;
			line-height: 26px;
			margin: 0 auto 16px auto;
		}

		&.small {
			font-size: 16px;
		}

		&.separator {
			text-align: center;
			color: $color-neutral-grey;
			font-family: var(--app-font-italic), serif;
			font-size: 16px;
			margin: 16px auto;
		}

		&.header {
			font-family: var(--app-font-bold), serif;
			color: $color-neutral-darker;

			&.medium {
				font-size: 24px;
			}
		}
	}

	&.pill {
		height: 56px;
		border: $border-light-grey;
		background-color: $color-neutral-white;
		font-family: var(--app-font-bold), serif;
		border-radius: $border-radius-default;
		font-size: $default-font-size-pill;
		color: $color-neutral-darker;
		display: flex;
		justify-content: center;
		align-items: center;

		&.active {
			background-color: var(--color-primary-base);
			border: $border-primary-dark;
			color: white;
			font-family: var(--app-font-bold), serif;
		}
	}

	&.long-input {
		border: $border-light-grey;
		width: 100%;
		border-radius: $border-radius-default;
		resize: none;
		margin: 0 auto;
		background-color: $color-neutral-white;
		display: block;
		min-height: 200px;
		outline: none;
		font-size: 16px;
		font-family: var(--app-font-regular), serif;
		color: $color-neutral-darker;
		padding: 16px;
	}
}

.text-icon-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	padding: 10px;
	font-size: 14px;
	font-family: var(--app-font-regular), serif;
	color: var(--color-primary-base);

	i {
		font-size: 24px;
	}

	&:hover {
		color: var(--color-primary-base);
	}
}

.label-content {
	display: flex;
	flex-direction: column;
	margin-bottom: 22px;

	.label {
		font-family: var(--app-font-bold), serif;
		font-size: 15px;
		padding-bottom: 4px;
	}
}

.round-button {
	cursor: pointer;
	width: 48px;
}

.brand-title {
	display: flex;
	align-items: center;

	img {
		width: 36px;
		margin-right: 12px;
	}

	.brand-name {
		font-family: var(--app-font-bold), serif;
		font-size: 24px;
		color: var(--color-primary-dark);

		span {
			color: var(--color-primary-base);
		}
	}
}


.split-view {
	&.desktop {
		.mha.module {
		}

		.mha.content {
			width: 100%;
		}
	}

	.split {
		display: flex;
		overflow: hidden;
		width: 93%;
		height: 100%;

		.split-item {
			display: flex;
			flex-direction: column;

			.mha.content {
				display: flex;
				flex-direction: column;
			}

			&.left {
				width: 33.3%;
				min-width: 368px;
				max-width: $width-max-button;
				height: 100%;
				overflow-y: scroll;
				overflow-x: hidden;

				&.no-scroll {
					overflow: hidden;
				}
			}

			&.right {
				flex: 1;
				height: 100%;
				margin-left: 36px;
				overflow-y: auto;

				.right-footer {
					width: 100%;
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.feed-view {
		.feed-items {
			padding-right: 0;
		}
	}
}

.absolute-center {
	width: fit-content;
	height: fit-content;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	position: absolute;
}

.floatingActionButton {
	border-radius: 50%;
	width: 54px;
	height: 54px;
	background-color: var(--color-primary-base);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;

	&.inactive {
		box-shadow: $box-shadow-floating-action;
	}
}

// Hide opentok alert modal, which displays for incompatible browsers.
// Such browsers unfortunately include the iOS app.
.OT_ModalDialog {
	display: none !important;
}