
<template>
	<div class="m-4">
		<ion-button fill="clear"
								class="star-button ion-no-padding">
			<ion-icon :icon="filled === true ? STAR_FILLED : STAR_OUTLINE"
								class="star-icon"/>
		</ion-button>
	</div>
</template>

<script lang="ts">
	import {Options, Prop, Vue} from "vue-property-decorator";
	import { IonButton, IonIcon } from "@ionic/vue";
	import { star, starOutline } from "ionicons/icons";

	@Options({components: {IonButton, IonIcon}})
	export default class Star extends Vue
	{
		@Prop({type: Boolean}) filled: boolean;

		STAR_FILLED = star;
		STAR_OUTLINE = starOutline;
	}

</script>

<style scoped>
.star-icon {
	object-fit: contain;
	font-size: 46px;
}
.star-button {
	height: 46px;
	width: 46px;
	animation: none;
}

</style>
