
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.attachment-select-wrapper {
		.attachment-display-wrapper {
			display: flex;
			flex-direction: row;
			margin: -8px -4px; // -8 vertical to offset ionic list padding

			.attachment-list-wrapper {
				margin: 0 4px;
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				max-width: 50%;
			}
		}
	}
