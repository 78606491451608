
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.zero-state-buttons {
		width: 100%;
		max-width: $width-max-button;
		margin: 0 auto;
	}
