
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.banner-overlay {
		min-height: 808px;

		.telehealth-image-container {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			flex-grow: 1;

			max-width: 63%;
			min-width: 640px;
			height: max-content;
			min-height: 596px;

			img {
				width: 820px;
				min-width: 627px;
			}
		}

		.signup-form {
			margin-top: 76px;
		}

		// override input & button height
		--input-field-height: 40px;
		--base-button-height: 40px;
	}

	.body-trailer {
		background-color: var(--color-neutral-white);

		.featured-container {
			h3 {
				color: var(--color-cmd-primary-base)
			}

			img {
				width: 100%;
				max-width: 1000px;
				height: 100%;
			}
		}

		.how-it-works-container {
			width: 60%;
			max-width: 1280px;
			margin: 0 auto;

			ul {
				padding: 0;

				li {
					@include mha-font-body-lg;

					// CloudMD's icons are slightly all slightly different sizes, standardize them
					.cmd-icon-container {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 34px;
						width: 34px;

						img {
							max-width: 34px;
						}
					}
				}
			}
		}

		.app-download-container {
			.logo {
				font-size: 88px;
				color: var(--color-primary-base);
			}

			p {
				@include mha-font-body-lg;
				white-space: pre-line;
				text-align: center;
			}

			.download-links {
				max-width: 200px;
			}
		}

		.review-container {
			.review-rating-1 {
				img {
					&.rating {
						max-height: 128px;
					}
					&.stars
					{
						max-height: 96px;
					}
				}
			}

			.review-images {
				img {
					max-height: 192px;
				}
			}
		}

		.devices-container {
			img {
				width: 100%;
				min-width: 280px;
				max-width: 560px;
			}
		}

		.how-can-we-help-container {
			max-width: 1040px;
			margin-left: -16px;
			margin-right: -16px;
		}
	}

	// =========== Break Points =================
	@media only screen and (max-width: 1340px) {
		.banner-overlay {
			.signup-form-row {
				justify-content: center;
			}
		}
	}

	@media only screen and (max-width: 1000px) {
		.banner-overlay {
			margin-top: 24px;

			.telehealth-image-container {
				display: none;
			}

			.signup-form {
				margin-top: 0;
			}
		}

		.body-trailer {
			.review-container {
				.review-rating-1 {
					justify-content: center;
				}
			}
		}
	}

	@media only screen and (max-width: 512px) {
		.banner-overlay {
			// inputs & buttons larger on mobile
			--input-field-height: 56px;
			--base-button-height: 56px;

			.header-text {
				@include mha-font-h2(--color-neutral-darker);
			}

			.signup-form {
				width: 100%;
				margin-left: 16px;
				margin-right: 16px;
			}
		}
	}

