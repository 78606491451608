@mixin scrollbar {
	::-webkit-scrollbar
	{
		width: 5px;
	}

	::-webkit-scrollbar-thumb
	{
		background-color: #7c7c7c70;
	}
}

@mixin split-content-right {
	width: 100%;
	background-color: white;
	border-radius: $border-radius-default;
}

@mixin mha-input-colors($border-base-color, $border-valid-color) {
	border-color: $border-base-color;
	&.invalid:not(.pristine) {
		border-color: var(--color-red-base);
	}
	&.required {
		&:not(.pristine) {
			border-color: var(--color-red-base);
		}
		&.valid:not(.pristine) {
			border-color: $border-valid-color;
		}
	}
}

@mixin mha-form-rows($input-padding: 4px)
{
	// follow bootstrap margin technique to make spacing work nicely
	.row {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		margin: 0 -#{$input-padding};

		.form-group {
			margin: 0 $input-padding;

			// force a form group size based on percentage
			&.col-20 {
				width: 20%;
			}
			&.col-40 {
				width: 40%;
			}
			&.col-60 {
				width: 60%;
			}
			&.col-80 {
				width: 80%;
			}
		}
	}
}

// spacing above the center location in the content wrapper. sometimes needed elsewhere
@mixin content-container-top-min-height() {
	min-height: 10vh;
	@media only screen and (max-height: 667px) {
		min-height: 5vh;
	}
	@media only screen and (max-height: 568px) {
		min-height: 0;
	}
}

@mixin primary-active-state {
	background-color: var(--color-primary-base);
	color: $color-neutral-white;
	font-family: var(--app-font-bold), serif;
	border-radius: $border-radius-default;

	& > * {
		color: $color-neutral-white;
	}
}

@mixin nav-button-states {
	&:hover {
		color: white !important;
		background-color: var(--color-primary-base);
		span {
			color: white !important;
		}
	}

	&:active {
		background-color: var(--color-primary-hover);
		color: white !important;
		span {
			color: white !important;
		}
	}
}

// color a single button
@mixin mha-button-color($color, $background-color, $border-color,
						$hover-color, $hover-background-color, $hover-border-color,
						$active-color, $active-background-color, $active-border-color) {
	color: $color;
	background-color: $background-color;
	border-color: $border-color;
	--background: $background-color;
	--color-focused: $active-color;
	--color-activated: $active-color;
	--background-activated: $active-border-color;
	--background-focused: $active-background-color;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

	&:active,
	&:focus,
	&:focus:active {
		color: $active-color;
		background-color: $active-background-color;
		border-color: $active-border-color;
	}

	@media only screen and (min-width: 769px) {
		&:hover {
			color: $hover-color;
			background-color: $hover-background-color;
			border-color: $hover-border-color;
		}
	}
}

// color a single button using brightness filter for hover / active states
@mixin mha-button-color-brightness($color, $background-color, $border-color,
 									$hover-brightness, $active-brightness) {
	color: $color;
	background-color: $background-color;
	border-color: $border-color;
	--background: $background-color;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

	&:hover {
		filter: brightness($hover-brightness);
	}

	&:active {
		filter: brightness($active-brightness);
	}
}

@mixin mha-button-pattern($color-base, $color-dark, $color-darker, $color-darkest, $text-color-base, $text-color-active: var(--color-neutral-white)) {

	// default pattern
	@include mha-button-color($text-color-base, var(--color-neutral-white), var(--color-neutral-lighter),
			$text-color-active, $color-base, $color-base,
			$text-color-active, $color-dark, $color-dark
	);

	&.colored {
		@include mha-button-color($color-base, var(--color-neutral-white), var(--color-neutral-lighter),
				$text-color-active, $color-base, $color-base,
				$text-color-active, $color-dark, $color-dark
		);
	}

	// same as default pattern but hover state is darker
	&.dark-hover {
		@include mha-button-color($text-color-base, var(--color-neutral-white), var(--color-neutral-light),
				$text-color-active, $color-dark, $color-dark,
				$text-color-active, $color-darker, $color-darker
		);
	}

	// button is coloured with light text, darker hover states
	&.fill {
		@include mha-button-color($text-color-active, $color-base, $color-base,
				$text-color-active, $color-dark, $color-dark,
				$text-color-active, $color-darker, $color-darker
		);
	}

	&.fill-light {
		filter: brightness(115%);
		@include mha-button-color-brightness(var(--color-neutral-white), $color-base, $color-base, 90%, 80%);
	}

	&.fill-dark {
		@include mha-button-color($text-color-active, $color-dark, $color-dark,
				$text-color-active, $color-darker, $color-darker,
				$text-color-active, $color-darkest, $color-darkest
		);
	}

	// same as default but text and border are colored with primary base
	&.colored-text-border {
		@include mha-button-color($color-base, var(--color-neutral-white), $color-base,
						$text-color-active, $color-base, $color-base,
						$text-color-active, $color-dark, $color-dark
		);
	}

	// only text is visible
	&.transparent {
		@include mha-button-color($color-base, transparent, transparent,
				$color-dark, transparent, transparent,
				$color-darker, transparent, transparent
		);
		box-shadow: none;
		--color-focused: $color-dark;
		--color-activated: $color-darker;
		--background-activated: transparent;
		--background-focused: transparent;
	}

	// similar to colored-text-border but text and border are colored different
	// and modified hover, focus, active states
	&.colored-text-border-fusion {
		@include mha-button-color(
						$text-color-base, var(--color-neutral-white), $color-base,
						$text-color-base, $color-dark, $color-base,
						$text-color-base, $color-darker, $color-base
		);
		// Overwriting hover because it was not respecting the background color
		&:hover {
			background-color: $color-dark; //#BCEEEA80
		}
		// Overwriting focus to match design
		&:focus {
			color: $text-color-base;
			background-color: var(--color-neutral-white);
			border-color: $color-base;
			border-width: 3px;
		}
		// Overwriting active border width as focus conflicts
		&:active {
			border-width: 1px;
		}
	}

	// button is/appears disabled
	&.disabled,
	&[disabled],
	&.disabledStyle {
		@include mha-button-color(var(--color-neutral-light-grey), var(--color-neutral-lightest), var(--color-neutral-lighter),
				var(--color-neutral-light-grey), var(--color-neutral-lightest), var(--color-neutral-lighter),
				var(--color-neutral-light-grey), var(--color-neutral-lightest), var(--color-neutral-lighter),
		);

		&.transparent {
			border: none;
			background-color: transparent;
		}

		box-shadow: none;
		--color: var(--color-neutral-grey);

		& > * {
			color: var(--color-neutral-grey);
			--color: var(--color-neutral-grey);
		}
	}

}

@mixin mha-base-color-pattern {

	// default / primary colour scheme
	@include mha-button-pattern(var(--color-whitelabel-primary-base), var(--color-whitelabel-primary-dark), var(--color-whitelabel-primary-darker),
			var(--color-whitelabel-primary-darkest), var(--color-neutral-darker));

	&.secondary {
		@include mha-button-pattern(var(--color-whitelabel-secondary-base), var(--color-whitelabel-secondary-dark), var(--color-whitelabel-secondary-darker),
				var(--color-whitelabel-secondary-darkest), var(--color-neutral-darker));
	}

	&.greyscale-light {
		@include mha-button-pattern(var(--color-neutral-lightest), var(--color-neutral-lighter), var(--color-neutral-light),
				var(--color-neutral-light-grey), var(--color-neutral-darker), var(--color-neutral-darker));
	}
	&.greyscale-dark {
		@include mha-button-pattern(var(--color-neutral-grey), var(--color-neutral-dark), var(--color-neutral-darker),
				var(--color-neutral-darkest), var(--color-neutral-darker));
	}
	&.danger {
		@include mha-button-pattern(var(--color-red-base), var(--color-red-dark), var(--color-red-darker),
				var(--color-red-darkest), var(--color-neutral-darker));
	}
	&.fusion-selector {
		@include mha-button-pattern(
						var(--color-kii-light-teal), 							// $color-base
				    var(--color-kii-mental-health-secondary),	// $color-dark,
            var(--color-kii-mental-health-primary), 	// $color-darker,
				    var(--color-kii-mental-health-primary), 	// $color-darkest,
            var(--color-kii-medical-care),						// $text-color-base,
            var(--color-kii-medical-care),						// $text-color-active,
		)
	}
}

@mixin safe-area-padding-top($padding-top: 0px)
{
	padding-top: calc(env(safe-area-inset-top) + #{$padding-top})
}
@mixin safe-area-padding-bottom($padding-bottom: 0px)
{
	padding-bottom: calc(env(safe-area-inset-bottom) + #{$padding-bottom})
}
