
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.kiosk-branded {
		width: 100%;
		max-width: 100vw;
		margin: 0 auto 16px auto;

		img {
			margin: 0 auto;
		}

		h4 {
			font-size: 28px;
			text-align: center;
			margin-bottom: 48px;
		}

		h3 {
			font-size: 28px;
			margin-top: 32px;
			text-align: right;
		}
	}
