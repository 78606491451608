
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.clinic-about-info {
		font-family: var(--app-font-regular), sans-serif;
		text-align: left;
		line-height: 1.4;
		color: $color-neutral-darker;
		height: fit-content;
		width: 100%;

		.info-header {
			font-size: 18px;
			font-weight: bold;
		}
		.info-body {
			white-space: pre-wrap;
			font-size: 17px;
			padding: 0;
			margin: 0;
		}
	}

