
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	ion-button {
		@include mha-base-color-pattern;
		--padding-end: 8px;

		span {
			margin-right: 4px;
			font-family: var(--app-font-regular), serif;
			font-size: 14px;
		}

		i {
			font-size: 16px;
		}
	}
