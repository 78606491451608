
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.dropdown {
		border: $border-light-grey;
		border-radius: $border-radius-default;
		background-color: white;
		width: 200px;
		position: absolute;
		z-index: $layer-top;
		top: 0;
		left: 0;
		overflow: hidden;
		box-shadow: 1px 1px 10px -4px rgba(0,0,0,0.4);

		.item {
			width: 100%;
			height: 50px;
			cursor: pointer;
			display: flex;
			align-items: center;
			font-weight: normal;
			font-family: var(--app-font-regular), serif;

			i {
				width: 50px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			span {
				flex-grow: 1;
				padding-left: 15px;
				font-size: 15px;
			}

			&:hover {
				background-color: var(--color-primary-base);
				color: white;
			}
		}
	}
