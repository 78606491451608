
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.button-container {
		max-width: $width-max-button;
		margin: 0 auto;
	}
	.understand-button {
		margin-top: 24px;
	}
