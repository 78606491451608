
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.org-image {
		flex: 1 1 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
