
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	@mixin app-chip-color($text, $background, $background-hover, $border, $border-hover) {
		--app-chip-text-color: #{$text};
		--app-chip-background-color: #{$background};
		--app-chip-background-hover-color: #{$background-hover};
		--app-chip-border-color: #{$border};
		--app-chip-border-hover-color: #{$border-hover};
	}

	@mixin app-chip-solid-color($text, $background)
	{
		@include app-chip-color($text, $background, $background, $background, $background)
	}

	.app-chip {
		--app-chip-border-style: solid;

		// colors
		&.primary, &.info, &.success, &.warning {
			@include app-chip-color(
					var(--color-whitelabel-primary-base),
					var(--color-whitelabel-primary-base),
					var(--color-whitelabel-primary-dark),
					var(--color-whitelabel-primary-base),
					var(--color-whitelabel-primary-dark)
			);
		}
		&.secondary {
			@include app-chip-color(
					var(--color-whitelabel-secondary-base),
					var(--color-whitelabel-secondary-base),
					var(--color-whitelabel-secondary-dark),
					var(--color-whitelabel-secondary-base),
					var(--color-whitelabel-secondary-dark)
			);
		}
		&.danger {
			@include app-chip-color(
					var(--color-red-base),
					var(--color-red-base),
					var(--color-red-dark),
					var(--color-red-base),
					var(--color-red-dark)
			);
		}

		// patterns
		.app-chip-pattern-layer {

			&.default {
				&:not(:hover) {
					@include app-chip-solid-color(var(--color-neutral-darker), var(--color-neutral-white));
				}
				&:hover {
					--app-chip-text-color: var(--color-neutral-white);
					--app-chip-background-hover-color: var(--app-chip-background-color);
					--app-chip-border-hover-color: var(--app-chip-border-color);
				}
			}

			&.fill {
				--app-chip-text-color: var(--color-neutral-white);
			}

			&.transparent {
				--app-chip-background-color: transparent;
				--app-chip-background-hover-color: transparent;
			}

			&.colored {
				@include app-chip-solid-color(var(--color-neutral-white), rgba(var(--color-whitelabel-primary-darkest-alpha), 0.6));
				--app-chip-border-style: none;
			}
		}
	}
