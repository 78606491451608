
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.care-cards {
		position: relative;
		display: flex;
		flex-direction: row-reverse;
		width: 100%;

		.page-column {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;

			$signup-form-preferred-padding: 32px;
			$form-width: calc(#{376px} + (#{$signup-form-preferred-padding} * 2));

			&.column-right {
				justify-content: flex-start;
				max-width: $form-width;
				padding: 0 16px 0 0;

				p {
					padding: 0;
				}
			}
			&.column-left {
			}

			.care-cards-why {
				margin-top: 160px;
				max-width: $mobile-screen-width;

				.header {
					font-size: 28px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.1;
					letter-spacing: normal;
				}
				.slot-wrapper {
					max-width: 344px;
				}
			}

			.care-cards-graphic {
				position: relative;
				width: 100%;
				height: auto;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				.foreground,
				.background {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: flex-start;
					width: 100%;
					min-width: 674px;
					overflow: hidden;
				}

				.background {
					position: absolute;
					top: 0;
					right: 0;
					margin-top: 136px;
				}

				.foreground {
					padding-bottom: 24px;

					.card-column {
						position: relative;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: center;

						&.offset {
							margin-top: 148px;
							margin-right: 120px;
						}
					}
				}
			}
		}
	}
	/* switch to vertical view on small screens */
	@media only screen and (max-width: 1130px) {
		.care-cards {
			flex-direction: column;
			align-items: center;

			.page-column {
				&.column-right {
					flex-grow: 0;
					align-items: center;
					justify-content: center;
					padding: 0 16px;
				}
				.care-cards-why {
					margin-top: 0;
					margin-bottom: 24px;

					.header,p {
						text-align: center;
					}
					.slot-wrapper {
						margin: 0 auto;
					}
				}
				.care-cards-graphic {
					.foreground,
					.background {
						top: 0;
						right: unset;
					}
					.foreground {
						.card-column {
							&.offset {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}
	/* switch to vertical view on small screens */
	@media only screen and (max-width: 460px) {
		.care-cards {
			.page-column {
				.care-cards-graphic {
					.background {
						display: none;
					}

					.foreground {
						flex-direction: column;
						align-items: center;

						.card-column {
							width: 100%;
							&.offset {
								margin-top: 0;
							}
						}
					}
				}
			}
		}
	}
