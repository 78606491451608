
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	@media only screen and (max-width: 768px) {
		ion-header /deep/ .header-container {
			display: none;
		}
	}
