
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.clinic-header {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position-y: 80%;
		background-position-x: 80%;
		width: 100%;
		height: 25vh;
		object-fit: cover;
	}

	.clinic-logo-container {
		margin-top: calc(-72px - #{$header-padding-bottom});
		width: 100%;
	}

	.button-container {
		width: 100%;
		margin-bottom: 8px;

		.row {
			width: 100%;
			margin: 0 -4px;

			.button-wrapper {
				width: 100%;
				margin: 0 4px;
			}

			// for larger screens & desktop
			@media only screen and (min-width: 667px) {
				display: flex;
				flex-direction: row;

				.button-wrapper {
					max-width: 256px;
				}
			}
		}
	}
	.content-footer-wrapper {
		margin-top: 24px;
	}

	.dependent-subheader {
		padding-top: 32px;
	}
