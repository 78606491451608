
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.mha-checkbox {
		display: flex;
		align-items: center;
		font-size: 15px;

		ion-checkbox {
			min-width: var(--size);
			min-height: var(--size);
		}

		ion-label {
			margin-left: 12px;
		}
	}
