
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.mha-date-selector {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		margin: 0 -2px;

		.col {
			margin: 0 2px;

			&.day {
				width: 22%;
			}
			&.month {
				width: 50%;
			}
			&.year {
				width: 28%;
			}
		}
	}
