input, select {
	transition: all 0.3s ease;
	width: 100%;
	font-size: 20px;
	background-color: white;
	font-family: var(--app-font-bold), serif;
	color: var(--color-neutral-darker);
	height: 72px;
	padding-left: 16px;
	border-radius: 8px;
	border: 1px solid var(--color-neutral-light);
	margin-bottom: 4px;

	&.error {
		&::placeholder {
			color: var(--color-red-base);
		}

		color: var(--color-red-base);
		border-color: var(--color-red-base);
	}

	&.warn {
		&::placeholder {
			color: var(--color-yellow-dark);
		}

		color: var(--color-yellow-dark);
		border-color: var(--color-yellow-dark);
	}
}

.buttons {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin: 0 auto;

	& > * {
		&:first-child {
			margin-right: 8px;
		}
	}
}

/deep/ .text {
	text-align: center;

	h5 {
		font-family: var(--app-font-bold), serif;
		font-size: 32px;
		line-height: 40px;
	}

	p {
		font-family: var(--app-font-regular), serif;
		font-size: 20px;
		line-height: 28px;
		padding: 0;
	}
}


@keyframes fade-in {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}
