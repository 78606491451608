
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.title {
		font-weight: 400;
		font-size: 36px;
		font-family: Marcellus, sans-serif;
	}

	.subtitle {
		font-weight: 400;
		font-size: 20px;
		font-family: Marcellus, sans-serif;
	}

	.background-white {
		background-color: white;
	}

