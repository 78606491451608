
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.patient-info-header {
		.patient-name {
			flex: 1 1 100px;
			overflow: hidden;

			h5 {
				width: 230px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.juno-links {
			flex: 0 0 166px;
			.micro-button {
				padding-left: 4px;
			}
		}

		.dependent-warning-bubble {
			border-radius: 44px;
			padding: 8px 16px;

			color: var(--color-neutral-white);
			background-color: var(--color-primary-base);
		}
	}
