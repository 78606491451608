
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.clinic-info-container {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		flex-grow: 1;
		width: 100%;
		min-width: fit-content;
		margin-bottom: 8px;

		&.constrain-width {
			max-width: 400px;
		}
		&.content-align-left {
			align-items: flex-start;
		}
	}

