
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	@media only screen and (min-width: 1200px) {
		#chat-toggle {
			display: none;
		}
	}

	.overlay-content-wrapper
	{
		z-index: 1;
	}

	.video-overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.event-spinner-container {
			max-width: 512px;
		}
	}

