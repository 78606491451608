
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	ion-chip {
		min-width: fit-content;

		ion-label {
			display: flex;
			align-items: center;

			i {
				display: none;
			}
		}
	}

	@media only screen and (max-width: 768px) {
		ion-chip {
			ion-label {
				i {
					display: unset;
				}

				span {
					display: none;
				}
			}
		}
	}
