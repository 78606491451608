
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.booking-form {
		width: 100%;

		.select {
			padding-bottom: 0;
			margin-bottom: 8px;
		}

		.book-btn {
			margin-top: 8px;
		}
	}
