
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.toggle-group {
		padding: 8px 24px;
		h6 {
			color: $color-neutral-grey;
		}
	}
	.divider {
		width: 100%;
		border-bottom: 2px solid $color-neutral-lighter;
	}
