
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.care-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 208px;
		height: 304px;
		border-radius: 16px;
		background-color: var(--color-neutral-white);
		box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
		padding: 8px;
		margin: 8px;

		.card-title {
			color: var(--color-neutral-darker);
			font-size: 22px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.18;
			letter-spacing: normal;
			text-align: center;

			&.line-one {
				margin-top: 16px;
			}
			&.line-two {

			}
		}
		.card-message {
			margin-top: 8px;
			color: var(--color-neutral-darker);
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: center;
		}

	}

	// larger cards on small screen
	@media only screen and (max-width: 460px) {
		.care-card {
			width: 304px;
		}
	}
