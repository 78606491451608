
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.split-pane {
		position: unset;
		min-height: 256px;

		display: flex;
		flex-direction: row;
		flex-grow: 1;

		&.no-header {
			height: 100vh;
		}
		.split-left {
			flex-grow: 1;

			&.split-pane-side {
				border: none;
				max-width: $width-split-feed;
			}
		}
		.split-right {
			&.split-pane-side {
				border: none;
				max-width: 100%;
			}
		}
	}

	.hide-left-enter-active,
	.hide-left-leave-active {
		max-width: unset !important;
		min-width: unset !important;
		transition: all 0.35s;
		transform: translateZ(0);
	}

	.hide-left-enter, .hide-left-leave-to {
		max-width: 0 !important;
		min-width: unset !important;
		opacity: 0;
		transform: translateZ(0);
	}

	.hide-left-enter-to, .hide-left-leave {
		max-width: 368px !important;
		min-width: unset !important;
		opacity: 1;
		transform: translateZ(0);
	}
