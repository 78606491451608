
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	@mixin card-pattern($text-color-left,
	                    $text-color-right,
						$color-base,
				        $color-dark,
						$border-color: $color-dark)
	{
		filter: none;
		border-color: $border-color;
		background-color: $color-base;
		.card-content {
			.primary,.header,.footer {
				color: $text-color-right !important;
			}
		}
		.left-card-border {
			background-color: $color-dark;
			.border {
				border: none !important;
				color: $text-color-left !important;
			}
			.telehealth-roundel {
				i {
					color: $color-dark;
				}
			}
		}
		.attachment-marker {
			color: $text-color-right;
		}
	}

	$left-border-width: 24px;
	.selection-card {
		width: 100%;
		max-width: $width-max-button;
		height: 104px;
		text-overflow: ellipsis;
		display: flex;
		border-radius: $border-radius-default;
		border: $border-light-grey;
		position: relative;
		margin: 0 auto 4px auto;

		@include card-pattern(var(--color-neutral-white), var(--color-neutral-darker),
				var(--color-neutral-white), var(--color-primary-base), var(--color-neutral-light));

		&.faded {
			@include card-pattern(var(--color-neutral-grey), var(--color-neutral-grey),
					var(--color-neutral-white), var(--color-neutral-lightest), var(--color-neutral-light));
		}
		&.active {
			@include card-pattern(var(--color-neutral-white), var(--color-neutral-white),
					var(--color-primary-base), var(--color-primary-base));
		}
		&.clickable {
			cursor: pointer;
		}

		.attachment-marker {
			font-size: 16px;
			color: var(--color-neutral-darker);
			position: absolute;
			top: 7px;
			right: 7px;
		}

		.icon-delete-2 {
			font-size: 24px;
			color: $color-red-base;
			position: absolute;
			top: -11px;
			right: -12px;
		}

		.left-card-border {
			width: $left-border-width;
			height: 100%;
			position: relative;
			border-top-left-radius: inherit;
			border-bottom-left-radius: inherit;
			text-align: center;

			.border {
				width: 50%;
				height: calc(100% + 2px);
				border-radius: inherit;
				border-top: $border-primary-dark;
				border-left: $border-primary-dark;
				border-bottom: $border-primary-dark;
				transform: translate(-1px, -1px);
			}

			$icon-size: 28px;
			$border-size: 2px;
			.icon-container {
				width: $icon-size;
				height: $icon-size;
				position: absolute;
				top: calc(50% - #{$icon-size/2});
				right: calc(#{$icon-size/2} * -1);
				z-index: 99;
				display: flex;
				justify-content: center;
				align-items: center;

				.telehealth-roundel {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					background-color: white;
					display: flex;
					justify-content: center;
					align-items: center;
					i {
						font-size: calc(#{$icon-size} - #{$border-size * 2});
					}
				}
			}
		}

		.card-content {
			width: 80%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 16px 16px 12px 20px;

			& > * {
				width: 100%;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			.header {
				font-family: var(--app-font-regular), serif;
				font-size: 18px;
				font-weight: bold;
				line-height: 26px;
			}

			.primary {
				font-family: var(--app-font-regular), serif;
				line-height: 16px;
				font-size: 14px;
				margin-bottom: 2px;
				font-weight: normal;
			}
			.footer {
				font-family: var(--app-font-regular), serif;
				font-size: 14px;
				line-height: 16px;
				font-weight: normal;
			}
		}

		&.secondary-card {
			$left-border-width: 72px;
			.left-card-border {
				width: $left-border-width;
				margin-right: 0;

				.border {
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					font-family: var(--app-font-regular), serif;
					font-size: 22px;

					.bottom {
						font-size: 22px;
					}
				}
			}

			.card-content {
				text-align: left;
				height: 100%;
				width: calc(100% - #{$left-border-width});

				.header {
					font-family: var(--app-font-regular), serif;
					line-height: 16px;
					font-size: 14px;
					font-weight: normal;
				}

				.primary {
					font-family: var(--app-font-regular), serif;
					font-size: 18px;
					font-weight: bold;
					line-height: 26px;
					margin-top: 8px;

					&.min-height {
						height: fit-content;
						min-height: 52px;

						&.overflow {
							height: 52px;
							overflow: hidden;
						}
					}
				}
				.footer {
					font-family: var(--app-font-regular), serif;
					font-size: 14px;
					line-height: 16px;
					margin-top: 4px;
					font-weight: normal;
				}
			}
		}
	}

	@media only screen and (min-width: 769px) {
		.selection-card {
			&.clickable:hover {
				@include card-pattern(var(--color-neutral-white), var(--color-neutral-white),
						var(--color-primary-base), var(--color-primary-base), var(--color-primary-dark));
			}
		}
	}

	@keyframes deleteFade {
		from {
			transform: translateX(0);
			opacity: 1;
		}

		to {
			transform: translateX(100%);
			opacity: 0;
		}
	}

	.deleted {
		animation: deleteFade 0.35s ease-out forwards;
	}
