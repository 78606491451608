
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.header-logo {
		align-items: center;
		max-width: 344px;
		display: flex;
		flex-direction: column;

		&.full-width {
			max-width: 100%;
		}

		.bottom-text {
			display: flex;
			justify-content: space-between;
			width: 100%;

			.right-text {
				font-size: 24px;
				font-family: var(--app-font-bold), serif;
				margin: 8px 0;
			}
		}

		&.center {
			margin: 0 auto;
		}

		img {
			width: 100%;
			max-width: 344px;
		}
	}
