
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.button-container {
		margin-top: 16px;
	}

	.status {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin-top: 64px;

		p {
			margin-top: 24px;
		}
	}

