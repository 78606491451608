
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.button-container {
		width: 100%;
		position: relative;
		margin-bottom: 4px;
		min-height: var(--base-button-height);
		height: var(--base-button-height);
		cursor: pointer;

		@include mha-font-body-md;
		text-align: center;

		&.small {
			min-height: unset;
			height: fit-content;
			width: fit-content;
			margin-top: 4px;
			margin-bottom: 4px;

			ion-button {
				height: 42px;
				font-size: 14px;
				font-family: var(--app-font-regular), sans-serif !important;
				line-height: 32px;
				min-width: 160px;
				width: fit-content;
			}
		}

		// just like small, but button fills width
		&.small-n-wide {
			@extend .small;
			width: 100%;

			ion-button {
				width: 100%;
			}
		}

		&.x-small {
			min-height: unset;
			height: fit-content;
			width: fit-content;
			margin-top: 2px;
			margin-bottom: 2px;

			ion-button {
				height: 32px;
				font-size: 14px;
				font-family: var(--app-font-regular), sans-serif !important;
				line-height: 32px;
				min-width: 64px;
				width: 100%;
			}
		}

		&.icon-only {
			min-height: unset;
			height: fit-content;
			width: fit-content;
			margin-top: 4px;
			margin-bottom: 4px;

			ion-button {
				height: 64px;
				font-size: 14px;
				font-family: var(--app-font-regular), sans-serif !important;
				line-height: 32px;
				min-width: 64px;
				width: 100%;
			}
		}

		.base-button, ion-button {
			width: 100%;
			height: 100%;
			outline: none;
			position: relative;

			@include mha-base-color-pattern;
		}
	}

	/* Media Queries */
	@media only screen and (min-width: 768px) {
		html {
			body {
				.mha.pill {
					&.base-button {

						@include mha-base-color-pattern;

						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}
	}
