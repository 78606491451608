
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.appointment-feed-wrapper {
		width: 100%;
	}
	.mha.text.separator {
		color: $color-neutral-grey;
		font-family: var(--app-font-italic), serif;
		font-size: 16px;
		margin: 32px auto;
	}

	@media only screen and (max-width: 768px) {
		.feed-items {
			width: 100%;
		}
	}
