
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


.tooltip {
  position: fixed;
	background: white;
	padding: 4px 8px 4px 2px;
	border-radius: $border-radius-default;
	border: $border-light-grey;
	display: flex;
	align-items: center;
	width: fit-content;
	z-index: 99999;

  &.success {
    color: $color-lime-base;
  }

  &.danger {
    color: $color-red-base;
  }

  span {
    font-size: 14px;
  }

  i {
    font-size: 12px;
  }
}

.icon-tooltip {
  position: relative;
}

