
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.verification-status {
		.check-icon {
			width: 16px;
			height: 16px;
			font-size: 12px;
			border-radius: 100%;

			color: var(--color-neutral-white);
		}

		&.verified {
			cursor: pointer;
			.check-icon {
				background-color: var(--color-lime-base);
			}
		}

		&.not-verified {
			user-select: none;
			cursor: pointer;

			.check-icon {
				background-color: var(--color-red-base);
			}

			.status-message {
				color: var(--color-red-base);
			}
		}

		&.unknown {
			.check-icon {
				background-color: var(--color-neutral-grey);
			}

			.status-message {
				color: var(--color-neutral-grey);
			}
		}
	}
