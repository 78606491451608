
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.status {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin-top: 64px;

		p {
			margin-top: 24px;
		}
	}

	@media only screen and (min-width: 721px) {
		.mha-text-spacer {
			text-align: left;
		}
	}

	.dark-mode {
		color: var(--color-neutral-lightest);
	}
