
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.signup-form {
		.form-content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			p, span {
				&.dark-mode {
					color: var(--color-neutral-lightest);
				}
			}

			p {
				text-align: center;
			}

			.mha-checkbox {
				margin-top: 16px;
				margin-bottom: 32px;
				align-self: start;
				padding-left: 8px;

				.terms-link {
					color: var(--color-whitelabel-primary-base);
					cursor: pointer;

					&:hover {
						color: var(--color-whitelabel-primary-dark);
						text-decoration: underline;
					}
				}
			}
		}
		.process-content {
			margin-top: 10vh;

			.dark-mode {
				color: var(--color-neutral-lightest);
			}
		}
	}
