
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.profile-control-button-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		padding-bottom: 24px;
		width: 100%;

		.btn-container {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: center;
		}

		.btn {

			@include mha-base-color-pattern;

			display: flex;
			align-items: center;
			justify-content: center;
			width: 88px;
		}
	}
