
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.app-download-frame {
		display: flex;
		flex-direction: row;
		width: 100%;

		.app-info {
			--text-max-width: 480px;

			padding: 0 36px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			text-align: right;
			width: 50%;

			.branded-logo {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 24px;
			}

			.header-text {
				font-stretch: normal;
				font-style: normal;
				line-height: 1.1;
				letter-spacing: normal;
				max-width: var(--text-max-width);

				&.org {
					font-size: 32px;
					font-weight: bold;
				}
			}
			p {
				max-width: var(--text-max-width);
			}
		}
		&.reversed-layout .app-info {
			align-items: flex-start;
			padding: 0 16px;
			text-align: left;

			--text-max-width: 440px;

			.download-links {
				max-width: 200px;
			}

			.logo {
				font-size: 88px;
				color: var(--color-primary-base);
			}
		}

		.app-graphic {
			position: relative;
			padding: 0 36px;
			width: 50%;
			display: flex;
			flex-direction: row;

			--phone-width-back: 238px;
			--phone-width-front: 251px;
			--overlap: 64px;

			.device-container {
				position: relative;
				display: flex;
				flex-direction: row;
				width: calc(var(--phone-width-back) + var(--phone-width-front) - var(--overlap));

				.device {
					position: relative;
					padding-top: 24px;
					height: 100%;

					&.back {
						padding-top: 47px;
						width:  var(--phone-width-back);
					}
					&.front {
						margin-left: calc(0px - var(--overlap));
						width:  calc(var(--phone-width-front) - var(--overlap));
					}
				}
			}
		}
		&.reversed-layout .app-graphic {
			justify-content: flex-end;
			padding: 0 16px;
			--overlap: 32px;

			.device-container .device {
				&.back {
					padding-top: 0;
					z-index: 1;
					width:  var(--phone-width-back);
				}
				&.front {
					padding-top: 16px;
					z-index: 0;
					width:  calc(var(--phone-width-front));
				}
			}
		}

		/* switch to vertical view on small screens */
		@media only screen and (max-width: 1000px) {
			flex-direction: column;
			align-items: center;

			.app-info {
				text-align: center;
				align-items: center;
				padding: 0 16px;
				width: 100%;

				.download-links {
					padding-bottom: 56px;
				}
			}
			.app-graphic {
				padding: 0 16px;
				width: 100%;
				justify-content: center;
			}

			&.reversed-layout {
				.app-info {
					align-items: center;
					.download-links {
						max-width: 100%;
					}
				}

				.app-graphic {
					justify-content: center;
				}
			}
		}

		@media only screen and (max-width: 456px) {
			.app-graphic {
				$scale-factor: 0.75;
				$x-offset: -32;
				.device-container {
					justify-content: center;
					width: 100%;

					.device {
						transform: matrix($scale-factor, 0, 0, $scale-factor, $x-offset, 0);
						&.front {
							margin-left: calc(#{$x-offset}px - var(--overlap));
						}
					}
				}
			}

			// this is what happens when you don't flexbox correctly.
			&.reversed-layout .app-graphic {
				$scale-factor: 0.75;
				$x-offset: 16;
				.device-container {
					justify-content: center;
					width: 100%;

					.device {
						max-width: 150px;
						transform: matrix($scale-factor, 0, 0, $scale-factor, $x-offset, 0);
						&.back {
							margin-left: -48px;
							margin-right: calc(#{$x-offset}px - var(--overlap));
						}
						&.front {
							margin-left:  calc(#{$x-offset}px - var(--overlap));
							margin-right: 48px;
						}
					}
				}
			}
		}
	}
