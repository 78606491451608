
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	$logo-offset: 64px;
	$card-width: 1040px;
	$card-height: 544px;

	.how-can-we-help-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		max-width: $card-width;
		min-height: $card-height;
		margin-top: #{$logo-offset};
		background-color: var(--color-neutral-white);
		box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
		border-radius: 16px;
		z-index: 100;

		.logo {
			margin-top: -#{$logo-offset};
		}
		.title {
			margin-top: 8px;
			font-size: 22px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: center;
			color: var(--color-neutral-darker);
			padding: 0 16px;
		}
		.message {
			margin-top: 4px;
			font-size: 20px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: center;
			color: var(--color-neutral-darker);
			padding: 0 16px;
		}
		.lists-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 32px 72px ;

			.list {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;

				ul {
					list-style: none;
					margin-top: 32px;
					padding-left: 24px;

					li {
						font-size: 16px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.2;
						letter-spacing: normal;
						color: var(--color-neutral-darker);
						padding: 8px 0;

						i {
							color: var(--color-primary-base);
							font-size: 24px;
							margin-right: 16px;
						}
					}
				}
			}
		}

		/* remove border-radius when screen smaller than card */
		@media only screen and (max-width: $card-width) {
			border-radius: 0;
		}

		// remove padding when screen is somewhat small
		@media only screen and (max-width: 1130px) {
			.lists-wrapper {
				justify-content: center;
				padding: 8px;
				.list {
					ul {
						padding-left: 8px;
					}
				}
			}
		}
	}
