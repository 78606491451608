
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.feed-view {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		.feed-items {
			flex-grow: 1;
			overflow-y: auto;
			overflow-x: hidden;
		}

		.empty-feed {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow-y: scroll;

			span {
				font-size: 20px;
				color: $color-neutral-light-grey;
			}
		}
	}
