
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.logo-wrapper {
		display: flex;
		justify-content: center;
	}
	/deep/ .modal-form {
		.mha.basic-input {
			padding-bottom: 8px;
		}
	}

	.instruction-text {
		margin: 16px 0 8px 0;
	}
