
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.file-chooser-wrapper {
		.file-chooser-input {
			display: none;
		}
	}

