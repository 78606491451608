
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.verification-cancel-popover {
		.button-text {
			font-size: var(--font-body-xs);
		}
	}
