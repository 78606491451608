
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.pulsing-indicator {
		// customization variables
		--pulse-color: var(--color-primary-base);

		@keyframes pulse {
			0% {
				filter: blur(0);

				box-shadow: 0 0  0.2em var(--pulse-color);
			}
			50% {
				filter: blur(1px);

				transform: scale(1.1);

				box-shadow: 0 0  0.3em var(--pulse-color);
			}
			100% {
				filter: blur(0);

				box-shadow: 0 0  0.2em var(--pulse-color);
			}
		}

		.indicator {
			height: 1em;
			width: 1em;

			box-shadow: 0 0  0.2em var(--pulse-color);
			background-color: var(--pulse-color);
			border-radius: 100%;

			&.pulse-active {
				animation: 2s linear 0s infinite pulse;
			}
		}
	}

