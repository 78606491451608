@import "~@/assets/styles/_fonts.scss";
@import "~@/assets/styles/_global_styles.scss";
@import "~@/assets/mha-icons/style.css";

* {
	box-sizing: border-box;
	background-repeat: unset;
}

input[type="text"], input[type="password"], textarea {
	background-image: linear-gradient(transparent, transparent);
	-webkit-appearance: none;
	appearance: none;
}

html {
	overflow-x: hidden;
	height: 100%;
	background-color: var(--app-bg);

	@include mha-font-defaults(var(--color-neutral-darker));
	@include default-font-style;

	h1, h2, h3, h4, h5 {
		margin: 0;
	}

	body {
		:where(#app ion-app) {
			@include mha-font-body-md;
		}

		height: 100%;
		background: var(--app-bg);
		margin: auto;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-webkit-tap-highlight-color: transparent;

		.global {
			pointer-events: none;
			display: flex;
			position: fixed;
			background: none;
			width: 100%;
			height: 100%;
			z-index: -1;
		}

		#app {
			width: 100vw;
			height: 100vh;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */

			.main {
				& > * {
					grid-area: main;
					position: relative;
					display: flex;
					flex-direction: column;
					height: 100%;
					width: 100%;
					background: var(--app-bg);
				}

				& > :first-child {
					z-index: 999;
					width: 100%;
				}

				&.normalize {
					overflow: unset;
					height: 100%;

					& > * {
						display: block;
					}

					.mha.module {
					}
				}
			}
		}

		a {
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			-webkit-tap-highlight-color: transparent;
			color: var(--color-neutral-darker);
			text-decoration: none;
		}
	}
}

@import "~@/assets/styles/MediaQueries/App.mq";
@import "./assets/styles/animations";
@import "./assets/styles/ionic-overrides";
@import "~@/assets/styles/mixins/global.scss";
/* Transitions */



button {
	outline: none;
	border: none;
	cursor: pointer;
}

.mha-button {
	outline: none;
	border: none;
	background: none;
	color: var(--color-primary-base);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-family: var(--app-font-bold), serif;
	padding: 4px 8px;
	border-radius: 8px;

	@include nav-button-states;
}

html,body {
	position: fixed;
	overflow: hidden;
}
