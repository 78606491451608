
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.scroll-container {
		position: relative;
		display: inline-block;
		width: 100%;
		overflow: hidden;

		&.overflow-x {
			overflow-x: auto;
		}
		&.overflow-y {
			height: 100%;
			overflow-y: auto;
		}
	}
