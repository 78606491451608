
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	p .bold {
		font-family: var(--app-font-bold), serif;
	}
	.confirmation {
		width: 100%;
	}
