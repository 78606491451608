@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?zi0als');
  src:  url('fonts/icomoon.eot?zi0als#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?zi0als') format('truetype'),
    url('fonts/icomoon.woff?zi0als') format('woff'),
    url('fonts/icomoon.svg?zi0als#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-1:before {
  content: "\e900";
}
.icon-2:before {
  content: "\e901";
}
.icon-3:before {
  content: "\e902";
}
.icon-4:before {
  content: "\e903";
}
.icon-5:before {
  content: "\e904";
}
.icon-6:before {
  content: "\e905";
}
.icon-7:before {
  content: "\e906";
}
.icon-8:before {
  content: "\e907";
}
.icon-9:before {
  content: "\e908";
}
.icon-10:before {
  content: "\e909";
}
.icon-11:before {
  content: "\e90a";
}
.icon-12:before {
  content: "\e90b";
}
.icon-13:before {
  content: "\e90c";
}
.icon-16:before {
  content: "\e90f";
}
.icon-17:before {
  content: "\e910";
}
.icon-18:before {
  content: "\e911";
}
.icon-19:before {
  content: "\e912";
}
.icon-20:before {
  content: "\e913";
}
.icon-21:before {
  content: "\e914";
}
.icon-22:before {
  content: "\e915";
}
.icon-23:before {
  content: "\e916";
}
.icon-24:before {
  content: "\e917";
}
.icon-account:before {
  content: "\e918";
}
.icon-add:before {
  content: "\e919";
}
.icon-alarm-off:before {
  content: "\e91a";
}
.icon-alarm:before {
  content: "\e91b";
}
.icon-archive:before {
  content: "\e91c";
}
.icon-arrow-down:before {
  content: "\e91d";
}
.icon-arrow-left:before {
  content: "\e91e";
}
.icon-arrow-right:before {
  content: "\e91f";
}
.icon-arrow-up:before {
  content: "\e920";
}
.icon-aside-closed:before {
  content: "\e921";
}
.icon-aside-open:before {
  content: "\e922";
}
.icon-asterisk:before {
  content: "\e923";
}
.icon-attachments:before {
  content: "\e924";
}
.icon-bars:before {
  content: "\e925";
}
.icon-billed:before {
  content: "\e926";
}
.icon-billing:before {
  content: "\e927";
}
.icon-blood:before {
  content: "\e928";
}
.icon-bloodpressure:before {
  content: "\e929";
}
.icon-bookmark:before {
  content: "\e92a";
}
.icon-breathing:before {
  content: "\e92b";
}
.icon-briefcase:before {
  content: "\e92c";
}
.icon-ca:before {
  content: "\e92d";
}
.icon-calendar-add:before {
  content: "\e92e";
}
.icon-calendar-search:before {
  content: "\e92f";
}
.icon-calendar-week:before {
  content: "\e930";
}
.icon-calendar:before {
  content: "\e931";
}
.icon-cancel:before {
  content: "\e932";
}
.icon-cancer:before {
  content: "\e933";
}
.icon-chat:before {
  content: "\e934";
}
.icon-check-Copy-1:before {
  content: "\e935";
}
.icon-check:before {
  content: "\e936";
}
.icon-chevron-down:before {
  content: "\e937";
}
.icon-chevron-left:before {
  content: "\e938";
}
.icon-chevron-right:before {
  content: "\e939";
}
.icon-chevron-up:before {
  content: "\e93a";
}
.icon-clinic:before {
  content: "\e93b";
}
.icon-close:before {
  content: "\e93c";
}
.icon-cloud-download:before {
  content: "\e93d";
}
.icon-cloud-upload:before {
  content: "\e93e";
}
.icon-cloud:before {
  content: "\e93f";
}
.icon-cmd:before {
  content: "\e940";
}
.icon-comment:before {
  content: "\e941";
}
.icon-cp-2:before {
  content: "\e942";
}
.icon-cp:before {
  content: "\e943";
}
.icon-cpx:before {
  content: "\e944";
}
.icon-day-sheet:before {
  content: "\e945";
}
.icon-delete:before {
  content: "\e946";
}
.icon-dot:before {
  content: "\e947";
}
.icon-ellipsis-h:before {
  content: "\e948";
}
.icon-ellipsis-v:before {
  content: "\e949";
}
.icon-emoji-negative:before {
  content: "\e94a";
}
.icon-emoji-neutral:before {
  content: "\e94b";
}
.icon-emoji-smile:before {
  content: "\e94c";
}
.icon-emoji-smirk:before {
  content: "\e94d";
}
.icon-empty:before {
  content: "\e94e";
}
.icon-exclamation:before {
  content: "\e94f";
}
.icon-external-link:before {
  content: "\e950";
}
.icon-fax:before {
  content: "\e951";
}
.icon-file-2:before {
  content: "\e952";
}
.icon-file:before {
  content: "\e953";
}
.icon-filter:before {
  content: "\e954";
}
.icon-find-a-clinic:before {
  content: "\e955";
}
.icon-flag:before {
  content: "\e956";
}
.icon-flip:before {
  content: "\e957";
}
.icon-gear:before {
  content: "\e958";
}
.icon-gears:before {
  content: "\e959";
}
.icon-genetics:before {
  content: "\e95a";
}
.icon-health-data:before {
  content: "\e95b";
}
.icon-health-tracker:before {
  content: "\e95c";
}
.icon-healthcare-2:before {
  content: "\e95d";
}
.icon-healthcare:before {
  content: "\e95e";
}
.icon-here:before {
  content: "\e95f";
}
.icon-info-circle:before {
  content: "\e960";
}
.icon-juno:before {
  content: "\e961";
}
.icon-linux:before {
  content: "\e962";
}
.icon-list:before {
  content: "\e963";
}
.icon-location-arrow:before {
  content: "\e964";
}
.icon-lock-open:before {
  content: "\e965";
}
.icon-lock:before {
  content: "\e966";
}
.icon-login:before {
  content: "\e967";
}
.icon-logout:before {
  content: "\e968";
}
.icon-mail:before {
  content: "\e969";
}
.icon-map-marker:before {
  content: "\e96a";
}
.icon-menu:before {
  content: "\e96b";
}
.icon-mha:before {
  content: "\e96c";
}
.icon-minimize:before {
  content: "\e96d";
}
.icon-mobile-app:before {
  content: "\e96e";
}
.icon-money:before {
  content: "\e96f";
}
.icon-nbox:before {
  content: "\e970";
}
.icon-noshow:before {
  content: "\e971";
}
.icon-phone:before {
  content: "\e972";
}
.icon-picked:before {
  content: "\e973";
}
.icon-play-circle:before {
  content: "\e974";
}
.icon-plus-circle:before {
  content: "\e975";
}
.icon-plus:before {
  content: "\e976";
}
.icon-print:before {
  content: "\e977";
}
.icon-private:before {
  content: "\e978";
}
.icon-question:before {
  content: "\e979";
}
.icon-refresh:before {
  content: "\e97a";
}
.icon-reply-all:before {
  content: "\e97b";
}
.icon-reply:before {
  content: "\e97c";
}
.icon-resize-max:before {
  content: "\e97d";
}
.icon-resize-min:before {
  content: "\e97e";
}
.icon-rotate:before {
  content: "\e97f";
}
.icon-search:before {
  content: "\e980";
}
.icon-send-round:before {
  content: "\e981";
}
.icon-send:before {
  content: "\e982";
}
.icon-share:before {
  content: "\e983";
}
.icon-sort-down:before {
  content: "\e984";
}
.icon-sort-up:before {
  content: "\e985";
}
.icon-sorted:before {
  content: "\e986";
}
.icon-star:before {
  content: "\e987";
}
.icon-starbill:before {
  content: "\e988";
}
.icon-stop:before {
  content: "\e989";
}
.icon-support:before {
  content: "\e98a";
}
.icon-tele-call:before {
  content: "\e98b";
}
.icon-tele-camera-flip:before {
  content: "\e98c";
}
.icon-tele-camera:before {
  content: "\e98d";
}
.icon-tele-doc-Copy-1:before {
  content: "\e98e";
}
.icon-tele-doc:before {
  content: "\e98f";
}
.icon-tele-hangup:before {
  content: "\e990";
}
.icon-tele-volume-down:before {
  content: "\e991";
}
.icon-tele-volume-mic-mute:before {
  content: "\e992";
}
.icon-tele-volume-mic:before {
  content: "\e993";
}
.icon-tele-volume-mute:before {
  content: "\e994";
}
.icon-tele-volume-off-Copy-1:before {
  content: "\e995";
}
.icon-tele-volume-off:before {
  content: "\e996";
}
.icon-tele-volume-up:before {
  content: "\e997";
}
.icon-temperature:before {
  content: "\e998";
}
.icon-todo:before {
  content: "\e999";
}
.icon-tooth:before {
  content: "\e99a";
}
.icon-trash:before {
  content: "\e99b";
}
.icon-user-add:before {
  content: "\e99c";
}
.icon-user-group:before {
  content: "\e99d";
}
.icon-user-md:before {
  content: "\e99e";
}
.icon-user-question:before {
  content: "\e99f";
}
.icon-user-search:before {
  content: "\e9a0";
}
.icon-user:before {
  content: "\e9a1";
}
.icon-video--3:before {
  content: "\e9a2";
}
.icon-video-2:before {
  content: "\e9a3";
}
.icon-video:before {
  content: "\e9a4";
}
.icon-view-off:before {
  content: "\e9a5";
}
.icon-view:before {
  content: "\e9a6";
}
.icon-weight:before {
  content: "\e9a7";
}
.icon-write:before {
  content: "\e9a8";
}
