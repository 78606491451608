
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.provider-chat-view {
		flex: 1 1;
		overflow-y: auto;
		max-height: 100%;
		background-color: var(--color-primary-darkest);

		.top-controls {
			position: absolute;
			top: 8px;
			left: 0;
			right: 0;
		}

		.save-to-encounter-btn {
			width: 358px;
		}

		.chat-container {
			background-color: var(--app-bg);
			border-radius: 8px;

			width: 392px;
			min-height: 350px;
			height: 70%;
			max-height: 800px;
		}

		.call-indicator {
			&.connected {
				--pulse-color: var(--color-lime-base);
			}
			&.not-connected {
				--pulse-color: var(--color-red-base);
			}
		}

		@media only screen and (max-width: 1000px) {
			padding: 64px 16px;

			.save-to-encounter-btn {
				width: 100%;
			}

			.chat-container {
				height: 100%;
				max-height: 100%;
				width: 100%;
			}
		}
	}
