
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	ion-list {
		--ion-item-background: none;

		ion-item {
			margin-bottom: 4px;
			--border-radius: 8px;
			--ion-item-background: #{$color-neutral-white};
			&.transparent {
				--ion-item-background: none;
			}

			&.item {
				--border-width: 1px;
				--border-color: #{$color-neutral-light};
				&.borderless {
					--border-width: 0;
				}
			}

			.item-label {
				font-size: 14px;
			}

			i {
				&.icon-lg {
					font-size: 32px;
				}
			}

			&:hover {
				--ion-item-background: #{$color-neutral-lighter};
				cursor: pointer;
			}
			.primary {
				color: var(--color-primary-base);
			}
			.info {
				color: $semantic-info-base;
			}
			.warning {
				color: $color-warning-button;
			}
			.danger {
				color: $color-critical-button;
			}
		}
	}
