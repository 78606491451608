// TODO: Remove usage of this file

.video-controls {
	display: flex;
	max-width: 568px;
	width: 100%;
	height: $height-footer-desktop;
	bottom: 20px;
	left: 0;
	right: 0;
	margin: 0 auto;
	position: absolute;

	.control-button-container {
		display: flex;
		width: 33%;
		justify-content: center;
		align-items: center;

		.control-button {
			height: 64px;
			width: 64px;
			background-color: $color-white-transparent;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			border-radius: 50%;
			box-shadow: 0 0 17px -3px rgba(0,0,0,0.3);

			i {
				font-size: 40px;
				color: white;
				text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
			}

			&.end-call {
				background-color: $color-red-transparent;
				color: white;
			}

			&.call {
				background-color: $color-green-base;
				color: white;
			}
		}
	}
}

.telehealth-icon {
	font-size: 40px;
	color: white;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.disabled {
	color: $color-neutral-light-grey;
}

@media only screen and (max-width: 768px)
{
	.video-controls {
		justify-content: space-between;

		.control-button-container {
			width: 25%;

			.control-button {
				height: 56px;
				width: 56px;
			}
		}
	}
}
