
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	$vertical-margin: 8px;
	$horizontal-margin: 16px;

	.desktop-left-menu-frame {
		margin-left: $horizontal-margin;
		margin-bottom: $vertical-margin;
	}
	.desktop-right-display-frame {
		height: calc(100% - #{$vertical-margin});
		background-color: $color-neutral-white;
		border-radius: 8px;
		padding: 4px; // counter inner content doing weird stuff to corners
		margin: 0 $horizontal-margin $vertical-margin $horizontal-margin;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	}
	.inbox-custom-footer {
		background: var(--app-bg);

		.compose-button {
			margin-bottom: 12px;
			margin-right: $horizontal-margin;
			float: right;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	// so headers look the same until unified like rest of app
	.custom-header-class {
		padding-bottom: $header-padding-bottom;
	}
