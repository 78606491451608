@keyframes fade-in-left {
	0% {
		transform: translateX(-350px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fade-in-top {
	0% {
		transform: translateY(-75px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes flip-vertical-right {
	0% {
		transform: rotateY(0);
	}
	100% {
		transform: rotateY(180deg);
	}
}

@keyframes wobble-hor-bottom {
	0%,
	100% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	15% {
		-webkit-transform: translateX(-15px) rotate(-6deg);
		transform: translateX(-15px) rotate(-6deg);
	}
	30% {
		-webkit-transform: translateX(15px) rotate(6deg);
		transform: translateX(15px) rotate(6deg);
	}
	45% {
		-webkit-transform: translateX(0px) rotate(-3.6deg);
		transform: translateX(0px) rotate(-3.6deg);
	}
	60% {
		-webkit-transform: translateX(6px) rotate(2.4deg);
		transform: translateX(6px) rotate(2.4deg);
	}
	75% {
		-webkit-transform: translateX(-3px) rotate(-1.2deg);
		transform: translateX(-3px) rotate(-1.2deg);
	}
}

@keyframes bounce-top {
	0% {
		-webkit-transform: translateY(-45px);
		transform: translateY(-45px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 1;
	}
	24% {
		opacity: 1;
	}
	40% {
		-webkit-transform: translateY(-24px);
		transform: translateY(-24px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	65% {
		-webkit-transform: translateY(-12px);
		transform: translateY(-12px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	82% {
		-webkit-transform: translateY(-6px);
		transform: translateY(-6px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	93% {
		-webkit-transform: translateY(-4px);
		transform: translateY(-4px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	25%,
	55%,
	75%,
	87% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		opacity: 1;
	}
}

@keyframes flip-scale-right {
	0% {
		-webkit-transform: translateX(0) rotateY(0) scale(1);
		transform: translateX(0) rotateY(0) scale(1);
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
	}
	50% {
		-webkit-transform: translateX(50%) rotateY(-90deg) scale(2);
		transform: translateX(50%) rotateY(-90deg) scale(2);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	100% {
		-webkit-transform: translateX(100%) rotateY(-180deg) scale(1);
		transform: translateX(100%) rotateY(-180deg) scale(1);
		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;
	}
}

@keyframes puff-out-center {
	0% {
		transform: scale(1);
		filter: blur(0px);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		filter: blur(2px);
		opacity: 0;
	}
}

@keyframes slide-in-elliptic {
	0% {

		transform: translateY(-600px) rotateX(-30deg) scale(0) translateZ(0);
		transform-origin: 50% 100%;
		opacity: 0;
	}
	100% {
		transform: translateY(0) rotateX(0) scale(1) translateZ(0);
		transform-origin: 50% 1400px;
		opacity: 1;
	}
}

@keyframes fade-out-bottom {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}
}

@keyframes shake-vertical {
	0%,
	100% {
		transform: translateY(0);
	}
	25%,
	55%
	{
		transform: translateY(-1.8px);
	}
	40%,
	70%
	{
		transform: translateY(1.8px);
	}
	80% {
		transform: translateY(0.8px);
	}
	90% {
		transform: translateY(-0.8px);
	}
}

@keyframes shadow-inset {
	0% {
		box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
	}
	100% {
		box-shadow: inset 0 0 14px 0px rgba(0, 0, 0, 0.5);
	}
}

@keyframes scale-in-center {
	0% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
	100% {
		transform: translate3d(0, 0, 500px);
		opacity: 0;
	}
}

@keyframes scale-out-center {
	0% {
		transform: translate3d(0, 0, 399px);
		opacity: 0;
	}
	100% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@keyframes slide-in-fwd-center {
	0% {
		-webkit-transform: translateZ(-1400px);
		transform: translateZ(-1400px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}
}

@keyframes slide-out-fwd-center {
	0% {
		transform: translateZ(0);
		opacity: 1;
	}
	45% {
		opacity: 0;
	}
	100% {
		transform: translateZ(-1400px);
	}
}

/* Mobile Router Transitions */

.next-leave-to {
	animation: leaveToLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
	z-index: 0;
}

.next-enter-to {
	animation: enterFromRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
	z-index: 999;
}

.prev-leave-to {
	animation: leaveToRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
	z-index: 999;
}

.prev-enter-to {
	animation: enterFromLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
	z-index: 0;
}

@keyframes leaveToLeft {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		transform: translate3d(-25%, 0, 0);
		filter: brightness(0.5);
	}
}

@keyframes enterFromLeft {
	from {
		transform: translate3d(-25%, 0, 0);
		filter: brightness(0.5)
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes leaveToRight {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		transform: translate3d(100%, 0, 0);
	}
}

@keyframes enterFromRight {
	from {
		transform: translate3d(100%, 0, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}