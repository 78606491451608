
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	ion-fab {
		ion-fab-button {
			--background-activated: var(--color-primary-dark);

			i {
				font-size: 32px;
			}
		}
	}
