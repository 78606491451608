html {
	body {
		#app {
			ion-app {
				--ion-font-family: var(--app-font-regular), serif;
				--ion-text-color: var(--color-neutral-darker);
			}

			ion-alert {
				.danger {
					color: var(--color-red-base);
				}

				* {
					font-family: var(--ion-default-font);
				}
			}

			ion-fab {
				ion-fab-button {
					&.fab-med {
						width: 48px;
						height: 48px;
					}
				}
			}


			ion-picker {
				.picker-toolbar {
					justify-content: space-between;
				}
			}

			ion-modal {
				--border-radius: 16px;

				&.fullscreen {
					--width: 100%;
					--height: 100%;
					--max-width: 100%;
					--max-height: 100%;
					--border-radius: 0;
				}

				&.medium-modal {
					--width: 424px;
					--height: 344px;

					@Media only screen and (max-width: 600px) {
						& > * {
							--width: 100%;
							--height: 100%;
							--border-radius: 0;
						}
					}
				}

				&.small-modal {
					--width: 360px;
					--height: 280px;

					@Media only screen and (max-width: 600px) {
						& > * {
							--width: 100%;
							--height: 100%;
							--border-radius: 0;
						}
					}
				}
			}

			ion-button {
				text-transform: capitalize;
				letter-spacing: unset;
				font-family: var(--app-font-bold), sans-serif;
				margin: 0;
				visibility: visible;

				&.no-text-transform {
					text-transform: unset;
				}
			}

			/* notch correction for iPhone x models */
			@media only screen and (max-width: 768px) {
				ion-app, .modal-wrapper {
					ion-content {
						&:not(.no-notch-correct) {
							--padding-top: env(safe-area-inset-top);
							--padding-bottom: env(safe-area-inset-bottom);
						}
					}
				}
				.modal-wrapper {
					background: var(--app-bg);
				}
			}

			.ion-page {
				justify-content: flex-start;
				background: var(--app-bg);
				overflow: hidden;

				ion-segment {
					background: var(--app-bg);
				}

				.header-md, .footer-md {
					&::after, &::before {
						background: none;
					}
				}

				ion-content {
					--background: var(--app-bg);
					--ion-padding: 16px;
					--keyboard-offset: 0px;

					&.no-scroll {
						--overflow: hidden;
					}

					/deep/ .inner-scroll, /deep/ .scroll-y {
						@include scrollbar;
					}
				}
				ion-footer {
					--ion-padding: 16px;
					--background: var(--app-bg);
				}

				ion-slides {
					ion-slide {
						& > * {
							width: 90%;
							margin: 0 auto;
						}

						@media only screen and (max-width: 768px) {
							& > * {
								width: 100%;
							}
						}
					}
				}
				ion-action-sheet {

					$action-sheet-padding: 16px;
					--max-width: calc( #{376px} + #{$action-sheet-padding} * 2);

					.action-sheet-group {
						margin: 0 8px 16px 8px;
						border-radius: 8px;
						--color: #333;
					}
					.action-sheet-button {
						margin-bottom: 0;
						font-size: 18px;
						font-weight: unset;

						.action-sheet-button-inner {
							justify-content: center;
						}

						&.danger {
							color: $color-red-base;
						}
						&.primary {
							color: var(--color-whitelabel-primary-base);
						}
						&.separator {
							border-bottom: 1px solid $color-neutral-light;
						}
					}
				}
			}
		}

		ion-toast {
			--background: #262626;
			--color: white;
			--button-color: white;
			--max-width: 600px;
			--border-radius: 8px;
		}
	}
}


.inner-scroll, .scroll-y {
	display: flex;
	flex-direction: column;
}
