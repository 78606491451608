
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.mha-tab-bar {
		background: unset;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-top: none;
		height: 64px;

		--margin-bottom: env(safe-area-inset-bottom);

		// notch correction
		margin-bottom: var(--margin-bottom);

		.mha-tab-button {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: unset;
			width: 126px;

			padding: 8px 24px;
			color: var(--color-primary-base);
			cursor: unset;

			&:hover {
				color: var(--color-primary-dark);
			}

			&.disabled {
				color: var(--color-neutral-light);
			}

			&:not(.disabled) {
				cursor: pointer;
			}

			i {
				font-size: 28px;
			}

			label {
				font-size: 11px;
			}
		}
	}
