
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.header-left {
		.back-override, ion-back-button {
			padding-right: 16px;
		}

		.back-override {
			--background-focused: rgba(56,128,255,0.1);
			--border-radius: 4px;
			--color: var(--ion-color-primary, var(--color-whitelabel-primary-base));
			--icon-margin-end: -5px;
			--icon-margin-start: -4px;
			--icon-font-size: 1.85em;
			--min-height: 32px;
			font-size: 17px;

			ion-icon {
				--font-size: var(--icon-font-size);
			}
		}

		.back-arrow-icon {
			font-size: 30px;
			cursor: pointer;

			&:hover {
				filter: brightness(1.2);
			}
		}
	}

	.header-left,.header-center {
		display: flex;
		align-items: center;
		color: var(--color-whitelabel-primary-base);

		i {
			font-size: 32px;
		}

		.header-icon {
			width: 40px;
			height: 40px;
			position: relative;

			img, a {
				height: inherit;
				width: inherit;
			}

			.notification {
				position: absolute;
				right: 0;
				top: 0;
				color: var(--color-neutral-white);
				display: flex;
				align-items: center;
				justify-content: center;
				width: 34px;
				height: 34px;
				border-radius: 50%;
				background-color: var(--color-red-base);
			}
		}

		.header-text {
			color: var(--color-whitelabel-primary-base);
			padding-left: 6px;
			font-family: var(--app-font-bold), serif;
			font-size: 20px;
			text-transform: capitalize;
		}
	}

	.header-center {
		display: flex;
		flex-direction: column;

		.header-text {
			padding-left: 0;
			text-transform: uppercase;
		}
	}

	.header-right {
		ion-button {
			--padding-end: 4px;
		}

		.header-close-button {
			font-size: 14px;
			display: flex;
			color: var(--color-whitelabel-primary-base);
			align-items: center;

			span {
				padding-right: 2px;
				font-family: var(--app-font-regular), serif;
			}

			i {
				font-size: 24px;
				font-weight: bold;
			}
		}

		.logout {
			margin-right: 15px;
		}
	}

	@media only screen and (min-width: 769px) {
		.hide-desktop {
			display: none
		}
	}
	@media only screen and (max-width: 768px) {
		.hide-mobile {
			display: none
		}
	}
