
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.account-edit-container {
		margin-bottom: 16px;
		width: 100%;
		@include mha-form-rows(4px);

		.section-header {
			margin-top: 32px;
			margin-bottom: 24px;
			&.dark-mode {
				color: var(--color-neutral-lightest);
			}
		}
		.sub-header {
			display: flex;
			justify-content: center;
			margin-top: 12px;
			margin-bottom: 16px;
			text-align: center;
			font-size: 14px;
			color: var(--color-neutral-grey);
			padding-top: 4px;
			padding-bottom: 4px;

			&.dark-mode {
				color: var(--color-neutral-lightest);
			}
		}
	}
