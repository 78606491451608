
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	ion-chip {
		--color: var(--app-chip-text-color);
		--background: var(--app-chip-background-color);

		border-style: var(--app-chip-border-style);
		border-width: 1px;
		border-color: var(--app-chip-border-color);

		box-shadow: 0 0 1px 0px var(--app-chip-border-color) inset, 0 0 1px 0px  var(--app-chip-border-color);

		&:hover {
			--background: var(--app-chip-background-hover-color);
			border-color: var(--app-chip-border-hover-color);
			box-shadow: 0 0 1px 0px var(--app-chip-border-hover-color) inset, 0 0 1px 0px  var(--app-chip-border-hover-color);
		}

		height: unset;

		ion-label {
			padding: 4px 8px;
		}
	}

