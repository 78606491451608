
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.operating-hours {
		min-width: fit-content;
		width: 100%;

		.operating-title {
			font-size: 18px;
			font-weight: bold;
		}

		.days-operation {
			line-height: 1.57;

			.day {
				text-transform: capitalize;
			}
		}
	}
