
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.select-menu {
		position: relative;
		height: var(--input-field-height);
		width: 100%;

		.options {
			width: 100%;
			border: 1px solid var(--color-neutral-light);
			border-radius: 8px;
			position: absolute;
			top: 0;
			overflow: hidden;
			height: var(--input-field-height);
			background-color: var(--color-neutral-white);

			@include mha-input-colors(var(--color-neutral-light), var(--color-whitelabel-primary-base));

			.no-select {
				font-size: 14px;
				color: var(--color-neutral-grey);
				font-family: var(--app-font-regular), serif;
			}

			.icon-down {
				font-size: 24px;
				text-align: center;
				transform: rotateZ(0);
				transition: transform 0.2s ease;

				&.rotate {
					transform: rotateZ(180deg);
				}
			}

			&.active {
				z-index: 2;
				height: auto;
				box-shadow: 0px 2px 25px -8px;

				border-bottom: none;
				border-radius: 8px 8px 0 0 ;

				&.drop-up {
					border-radius: 0 0 8px 8px;
					border-top: none;
					border-bottom: 1px solid var(--color-neutral-light);

					box-shadow: 0px 16px 32px -16px;

					.icon-chevron-down {
						transform: rotate(180deg) translateY(50%);
					}
				}
			}

		}

		.dropdown-container {
			position: relative;
			top: var(--input-field-height);

			// allows the shadow of the dropdown to not overlap other adjacent elements
			&:before {
				content: "";

				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;

				z-index: 1;
				box-shadow: 0px 0px 25px -6px;
				border-radius: 8px;
			}

			.scroll-overflow {
				// clip the scroll bar, making it appear round when scrolling to the bottom.
				overflow: hidden;
				border-radius: 0 0 8px 8px;

				.options-list {
					z-index: 601;
					width: 100%;

					background-color: var(--color-neutral-white);

					border: 1px solid var(--color-neutral-light);
					&:not(.pristine) {
						&.required {
							border-color: var(--color-whitelabel-primary-base);

							&.invalid {
								border-color: var(--color-red-base);
							}
						}
					}

					border-top: none;
					border-radius: 0 0 8px 8px;

					overflow-y: auto;
					position: relative;

					.option:hover {
						color: white;
						background-color: var(--color-whitelabel-primary-base);
					}

					&.nostyle {
						.option {
							&.selected {
								height: unset;
							}
						}
					}

					&.input-select {
						.option.selected {
							padding: 0;

							/deep/.basic-input {
								.left-icon {
									font-size: 24px;
								}
							}
						}
					}
				}
			}

			&.drop-up {
				top: 0;
				transform: translateY(-100%);

				.scroll-overflow {
					border-radius: 8px 8px 0 0;
					.options-list {
						border-radius: 8px 8px 0 0;

						border-bottom: none;
						border-top: 1px solid var(--color-neutral-light);
					}
				}
			}
		}

		.option {
			user-select: none;
			display: flex;
			align-items: center;
			padding: 0 16px 0 16px;
			cursor: pointer;
			color: var(--color-neutral-dark-grey);
			justify-content: space-between;
			font-size: 14px;

			span {
				display: flex;
				align-items: center;

				i {
					margin-right: 8px;
					font-size: 22px;
				}
			}

			&:active,&.active{
				color: white;
				background-color: var(--color-whitelabel-primary-base);
			}

			&.disabled {
				span {
					opacity: 0.4;
				}

				&:active {
					color: var(--color-neutral-darker);
					background-color: white;
				}
			}

			.icon-check {
				color: var(--color-neutral-white);
			}

			&.selected {
				height: var(--input-field-height);
				color: var(--color-neutral-darker);
				font-size: 15px;

				.selected-content {
					width: 100%;
					display: flex;
					justify-content: space-between;
				}

				&:active {
					color: var(--color-neutral-white);
				}
			}
		}
	}

	// ========================= Break Points =================================

	@media only screen and (min-width: 769px)
	{
		.select-menu {
			.options {
				.option {
					&:hover {
						color: var(--color-neutral-white);
						background-color: var(--color-whitelabel-primary-base);
					}

					&.selected {
						&:hover {
							color: var(--color-whitelabel-primary-base);
							background-color: inherit;
						}
					}

					&.disabled {
						&:hover {
							font-family: var(--app-font-regular), serif;
							font-size: 15px;
							color: var(--color-neutral-darker);
						}
					}
				}

				&.nostyle {
					.option.selected:hover {
						color: unset;
					}
				}
			}
		}
	}
