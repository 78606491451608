
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

.input-layout {
	width: 100%;
	margin: 0;
	padding: 0 0 16px 0;
	display: flex;
	flex-direction: column;
	z-index: 1;

	@include mha-input-colors(var(--color-neutral-light), var(--color-whitelabel-primary-base));
	&.error {
		label {
			animation: wobble-hor-bottom 0.55s both;
			color: var(--color-red-base);
		}
	}
	&.required {
		label::after {
			@include mha-font-body-xs(var(--color-red-base));
			content: "*" attr(data-required-text);
			padding-left: 2px;
		}
		&.valid {
			label::after {
				color: var(--color-whitelabel-primary-base);
			}
		}
	}

	&.compact {
		padding: 0 0 4px 0;

		.input-label-div {
			label {
				margin-bottom: 0;
			}
		}
	}
	&.nopadding {
		padding: 0;
	}

	.input-label-div
	{
		$label-h-padding: 14px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;

		label {
			margin-left: $label-h-padding;
			margin-bottom: 16px;
			width: fit-content;
			display: inline-block;
			overflow: hidden;
			text-align: left;
			@include mha-font-body-sm(var(--color-neutral-grey));
		}
		.validationMessage
		{
			margin-right: $label-h-padding;
			display: inline-block;
			padding-left: 4px;
			@include mha-font-body-xs(var(--color-red-base));
		}
	}

	.input-container {
		position: relative;
	}

	&.nostyle {
		padding: 0;

		&.input-container {
			margin: 0;
			padding: 0;
			max-width: unset;
		}
	}
}

