
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	@keyframes shake {
		0% {
			transform: rotate(2deg);
		}
		50% {
			transform: rotate(-2deg);
		}
		100% {
			transform: rotate(2deg);
		}
	}

	::-webkit-input-placeholder {
		color: var(--color-neutral-dark);
		opacity: 0.5;
	}

	:-ms-input-placeholder {
		color: var(--color-neutral-dark);
		opacity: 0.5;
	}

	::placeholder {
		color: var(--color-neutral-dark);
		opacity: 0.5;
	}

	.mha {
		&.basic-input {
			position: relative;
			display: flex;
			align-items: center;
			border-radius: $border-radius-default;
			border: 1px solid;
			background-color: var(--color-neutral-white);
			width: 100%;
			// Note: can't set height here, it breaks the auo-grow text area
			@include mha-input-colors(var(--color-neutral-light), var(--color-whitelabel-primary-base));

			.value {
				height: var(--input-field-height);
				text-align: left;
				padding-left: 16px;
				@include mha-font-body-md;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			i {
				color: var(--color-neutral-dark);
				display: inline-block;
				z-index: 2;
				font-size: 16px;

				&.left-icon {
					margin-left: 12px;
				}

				&.right-icon {
					margin-right: 12px;
				}

				&.icon-view, &.icon-view-off {
					font-size: 24px;
					cursor: pointer;
				}

				&.icon-check {
					color: $color-green-base;
				}

				&.icon-delete {
					color: var(--color-red-base);
				}

				&.icon-asterisk {
					color: var(--color-red-base);
				}
			}

			/deep/ion-input {
				height: var(--input-field-height);
				display: inline-block;
				outline: none;
				@include mha-font-body-md(var(--color-neutral-darker));
				overflow: hidden;
				text-overflow: ellipsis;
				padding: 0 48px 0 24px;
				transform: translateZ(0);
				text-align: left;
				z-index: 1;
				--padding-start: 16px;

				input {
					height: 100%;
				}

				&.paddingLeft {
				}

				&:disabled {
					background: none;
					outline: none;

					/* Prevent Safari from changing the color and opacity of text in disabled inputs */
					-webkit-text-fill-color: var(--color-neutral-darker);
					-webkit-opacity: 1;
				}

				&.upper {
					text-transform: uppercase;
				}

				&.lower {
					text-transform: lowercase;
				}
			}
			:deep(ion-textarea) {
				--internal-padding: 16px;
				min-height: calc(1em + var(--internal-padding) * 2);
				display: inline-block;
				outline: none;
				@include mha-font-body-md(var(--color-neutral-darker));
				overflow: hidden;
				text-overflow: ellipsis;
				padding: 0 var(--internal-padding);
				transform: translateZ(0);
				text-align: left;
				z-index: 1;
				textarea {
					min-height: var(--input-field-height);
					padding-left: 0;
				}
			}

			&.nostyle {
				padding: 0;
				border: none;
				border-radius: 0;

				/deep/ ion-input,
				/deep/ ion-textarea {
					--padding-start: 0 !important;
					-webkit-padding-start: 0 !important;
					padding-inline-start: 0 !important;
					padding: 0;
				}
			}
		}
	}
