
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.header-container {
		/*background: var(--color-neutral-lightest);*/
		height: $height-header;
		width: 100%;
		z-index: $layer-top;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;

		&.overlay {
			position: absolute;
			background-image: none;
			top: 0;
			left: 0;

			&.white {
				.header {
					.header-item {
						& > * {
							color: white !important;
						}
					}
				}
			}
		}

		.header {
			display: flex;
			width: 100%;
			align-items: center;
			height: fit-content;

			.header-item {
				width: fit-content;

				img {
					width: 40px;
					height: 40px;
				}

				&.center {
					flex-grow: 1;
					width: unset;
				}
			}
		}

	}
