
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.patient-details {
		.row-item {
			.value {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&:first-child {
				width: 210px;
			}
		}
	}
