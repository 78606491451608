
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	deep(.content-wrapper) {
		height: 100%;

		.content-body-container {
			height: 100%;
		}
	}
