.top-content {
	width: 87%;
	margin: 0 auto;
	max-width: $width-max-button;
}

.no-appointments {
	margin-top: 16px;

	img {
		margin: 0 auto;
		display: block;
		width: 60%;
	}

	span {
		margin-top: 16px;
		display: block;
		text-align: center;
	}
}

.slot-container {
	overflow-y: auto;
	position: relative;
	height: 100%;
}

.appointment-times {
	display: flex;
	justify-content: space-around;
	max-width: 376px;
	margin: 0 auto;

	.slot-container {
		margin-top: 36px;
	}

	.column {
		display: flex;
		flex-direction: column;
		align-items: center;
		min-width: 33%;
		width: 100%;
		padding-right: 8px;

		&:last-of-type {
			padding-right: 0;
		}

		.column-header {
			margin-bottom: 24px;
			font-family: var(--app-font-regular), serif;
		}

		.column-items {
			width: 100%;

			.time-slot {
				background-color: white;
				border: $border-light-grey;
				border-radius: $border-radius-default;
				margin: 0 0 8px 0 !important;
				height: 56px;
				width: 100%;
				display: flex;
				font-size: 16px;
				justify-content: center;
				align-items: center;
				color: $color-neutral-darker;
				text-transform: unset !important;

				&:hover {
					cursor: pointer;
				}

				span {
					font-family: var(--app-font-regular), serif;
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) {

	.top-content {
		width: 100%;
	}

	.no-appointments {
		margin-top: 36px;
	}

	.time-slot {
		&:hover {
			background-color: var(--color-primary-base) !important;

			span {
				color: white !important;
			}
		}
	}
}
