
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.waiting-room-overlay {
		@import "../../telehealth/scss/TeleHealthVideoControls";

		background-repeat: no-repeat;
		background-size: cover;
		background-color: var(--app-bg);
		min-height: calc(100vh - #{$header-total-height});

		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 24px;

		.white-label-logo {
			flex-grow: 0;
		}

		/deep/ .spinner-light-text {
			h4, p {
				max-width: 376px;
			}
		}

		h1, h2 {
			text-align: center;
		}

		.header {
			font-size: 32px;
			font-weight: bold;
			line-height: 1.05;
			padding-top: 16px;
		}

		p {
			text-align: center;
		}

		.content-div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.branded-logo {
			margin: 0 0 24px 0;
		}

		.timer-text {
			font-size: 99px;
			font-weight: bold;
		}

		.button-container {
			display: flex;
			width: 100%;
			justify-content: center;
		}
		.page-button {
			width: 100%;
			padding: 0 32px 0 32px;
			max-width: 344px;
		};
	}
