
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	@import "./App.scss";

	.slide-enter, .slide-leave-to {
		transform: translate3d(0, -101%, 0);
	}

	.slide-enter-to, .slide-leave {
		transform: translate3d(0, 0%, 0);
	}

	.patient-data-spinner  {
		@keyframes patient-data-fade-in{
			0% {
				opacity: 0.0;
			}

			100% {
				opacity: 1.0;
			}
		}

		animation: patient-data-fade-in 3s;
	}
