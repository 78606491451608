
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.base-calendar {
		font-family: var(--app-font-regular), serif;
		color: $color-neutral-darker;
		width: 100%;
		margin: 0 auto;
		padding: 20px 0;

		.weekdays {
			display: grid;
			width: 100%;
			grid-template-columns: repeat(7, 1fr);
			grid-column-gap: 5px;
			font-size: 14px;
			margin-bottom: 10px;

			& > div {
				display: flex;
				justify-content: center;
			}
		}

		.day-grid {
			display: grid;
			grid-template-columns: repeat(7, 1fr);
			grid-column-gap: 5px;
			grid-row-gap: 5px;
			margin: 0 auto;
			padding: 0;
			font-size: 16px;
			justify-content: center;

			.day {
				display: flex;
				justify-content: center;

				.day-content {
					width: 40px;
					height: 40px;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					color: $color-neutral-light-grey;
					cursor: default;

					.container {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 100%;
						border-radius: inherit;
						position: relative;
					}

					&.today {
						color: var(--color-primary-base);
						font-family: var(--app-font-bold), serif;
					}

					&.active {
						@include primary-active-state;
					}

					&.bookable {
						color: $color-neutral-darker;
						cursor: pointer;

						.has-appointments {
							width: 4px;
							height: 4px;
							border-radius: 4px;
							position: absolute;
							bottom: 2px;
							background-color: var(--color-primary-base);
						}
					}
				}
			}
		}

		&.appointments {
			.day-grid {
				.day {
					.day-content {
						color: $color-neutral-darker;

						&.today {
							color: var(--color-primary-base);
						}
					}
				}
			}
		}
	}

	/* Media Queries */
	@media only screen and (min-width: 768px) {
		html {
			.base-calendar {
				padding: 0;
				margin-top: 20px;

				.day-grid {
					.day {
						.day-content {
							border-radius: 40px;
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: 768px) {
		html {
			.base-calendar {
				.day-grid {
					.day {
						.day-content {
							&.bookable {
								&:hover, &:active, &.active {
									@include primary-active-state;
								}
							}
						}
					}
				}
			}
		}
	}
