
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


.telehealth-page-wrapper {
  height: 100%;
  width: 100%;

  .telehealth-loader-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

	.menu-pane {
		height: 100%;
		flex: 0 0 432px;
		overflow-y: auto;

		.chat-view {
			flex: 1 1;
		}

		hr {
			width: 100%;
			min-height: 1px;
			margin-left: -4px;
			margin-right: -4px;

			background-color: var(--color-neutral-light);
		}

		@media only screen and (max-width: 1000px) {
			display: none;
		}
	}

	.video-view {
		flex: 1 1;
	}
}

.telehealth-spinner {
  max-width: 480px;
}

