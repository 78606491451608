
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.video-view {
		width: 100%;
		height: var(--video-view-height);
		position: relative;
		background-color: #1a2033;

		:deep(#subscriber.video-filter .OT_video-element) {
			filter: url("#video-filter");
		}

		:deep(#subscriber) {
			position: absolute;
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			z-index: 1;

			.OT_subscriber {
				flex: 1 1 50%;
			}

			.OT_widget-container {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.OT_video-poster {
					max-height: 128px;
				}
			}
		}

		.ui-overlay {
			position: absolute;
			height: 100%;
			width: 100%;
			overflow: hidden;
			z-index: 2;

			.absolute-overlay {
				position: absolute;
				height: 100%;
				width: 100%;
			}

			.bottom-controls {
				height: 88px;
				width: 100%;
				position: absolute;
				bottom: 20px;
				left: 0;
				right: 0;
				margin: 0 auto;
				z-index: 2;

				.video-controls {
					transition: opacity 0.35s, transform 0.5s;
					opacity: 0;
					transform: translate3d(0,100%,0);
					position: relative;
					z-index: 2;
				}

				:deep(#publisher) {
					overflow: hidden;
					position: absolute;
					height: 170px;
					width: 110px;
					z-index: 1;
					bottom: 0;
					left: 50px;
					background-color: #151515;
					opacity: 0;
					border: 2px solid white;
					border-radius: 8px;
					transform: translate3d(0,0,0);
					transition: transform 0.5s 0.08s, opacity 0.35s;

					@media only screen and (max-width: 768px) {
						height: 118px;
						width: 80px;
						left: 24px;
					}

					&.publishing {
						opacity: 1;
					}

					.muted-overlay {
						cursor: pointer;
						position: absolute;
						inset: 0;
						z-index: 2;
						background-color: rgba(var(--color-neutral-darkest-alpha), 0.6);
						display: flex;
						justify-content: center;
						align-items: center;

						i {
							color: white;
							font-size: 72px;
							opacity: 0.9;
						}
					}
				}

				&.active {
					:deep(#publisher) {
						transform: translate3d(0,(calc(#{$height-footer-desktop} * -1 - 20px),0));
					}

					.video-controls {
						opacity: 1;
						transform: translate3d(0,-32px,0);
					}
				}
			}

			.header-container {
				height: 64px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.top-controls {
					width: 100%;
					height: 64px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					z-index: 1;
					padding: 0 16px;

					.call-duration {
						color: white;
						text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
					}
				}
			}
		}
	}

	.slide-down-enter-active,
	.slide-down-leave-active,
	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: opacity .35s, transform .5s;
	}

	.slide-down-enter, .slide-down-leave-to {
		transform: translate3d(0, 90%, 0);
		opacity: 0;
	}

	.slide-down-enter-to, .slide-down-leave {
		transform: translate3d(0, 0%, 0);
		opacity: 1;
	}

	.slide-up-enter, .slide-up-leave-to {
		transform: translate3d(0, -90%, 0);
		opacity: 0;
	}

	.slide-up-enter-to, .slide-up-leave {
		transform: translate3d(0, 0%, 0);
		opacity: 1;
	}

