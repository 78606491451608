
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.calendar {
		max-width: $width-max-button;
		margin: 0 auto;

		.month-switcher {
			display: flex;
			justify-content: space-evenly;

			.month {
				width: 33%;
				text-align: center;
				font-size: 16px;

				.month-select {
					cursor: pointer;

					&.selected {
						font-family: var(--app-font-bold), serif;
					}
				}
			}
		}

		.calendar-displays {
			display: flex;
		}

		&.singleWeek {
			margin-bottom: 20px;
		}

		&.disabled {
			color: $color-neutral-light !important;
			opacity: 0.5;

			.calendar-days {
				color: $color-neutral-light !important;
			}
		}
	}
