
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.document-preview {
		height: 128px;
		max-width: 256px;

		&.loading {
			filter: brightness(0.6);
		}
	}

	.loading-overlay {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: absolute;
		inset: 0;
	}
