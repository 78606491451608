
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.slide-fade-enter-active {
		transition: background-color .2s ease;
	}

	.slide-fade-enter, .slide-fade-leave-to {
		background-color: white;
	}

	.input-modal {
		padding-bottom: 16px;

		.title-container {
			margin-top: 24px;
			margin-bottom:8px;
		}
	}

	@media only screen and (max-width: 768px) {
		.overlay {
			.modal-form {
				margin: 16px auto 16px;
			}
		}
	}
