/* Media Queries */
@media only screen and (min-width: 768px) {
	* {
		@include scrollbar;
	}

	html {
		body {
			background-color: #42413f;

			#app {
				margin: auto;
				flex: unset;

				&:first-child {
					width: 87%;
					margin: 0 auto;
				}

				.mha {
					&.module {

						.content {
							height: 100%;

							&.center {
								padding-top: 9vh;
							}

							&.dk-2 {
								.text.helper {
									font-size: 1em;
									padding: 10px 0;
									text-align: center;
								}
							}
						}

						.long-input {
							min-height: 256px;
						}
					}
				}
			}
		}
	}
}
