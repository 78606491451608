
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.footer-bar {
		font-family: var(--app-font-bold), serif;
		font-size: 15px;
		height: $height-footer-mobile;
		padding: 0 16px env(safe-area-inset-bottom) 16px;
		box-sizing: content-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		background-color: $color-neutral-white;

		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

		ion-button {
			--box-shadow: none;
			--padding-end: 0;
			--padding-start: 0;
		}

		a {
			color: var(--color-primary-base);
		}

		&.single {
			.action-box {
				&.left {
					width: 100%;
					justify-content: flex-start;
					margin: 0 auto;

					span {
						width: fit-content;
					}
				}
			}
		}

		&.full {
			width: 100%;
		}

		&.two {
			.action-box {
				width: 50%;
			}
		}

		.action-box {
			width: calc(100% / 3);
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			.left-side {
				justify-content: flex-start;
				margin-left: 0 !important;
				margin-right: auto !important;
				--padding-end: 12px;
			}

			.right-side {
				justify-content: flex-end;
				margin-left: auto !important;
				margin-right: 0 !important;
				--padding-start: 12px;
			}

			.center {
				margin: 0 auto;
			}

			ion-fab-button {
				img {
					width: 100%;
					height: 100%;
				}
			}

			span {
				display: flex;
				align-items: center;
				cursor: pointer;

				.footer-btn-text {
					font-size: 14px;

					&.right {
						margin-right: 3px;
					}

					&.left {
						margin-left: 3px;
					}
				}

				img {
					width: 24px;
				}
			}
		}
	}

	/* Media Queries */
	@media only screen and (min-width: 768px) {
		.footer-bar {
			height: $height-footer-desktop;

			.action-box
			{
				.left.button {
					margin-left: 0;
				}

				.right.button {
					margin-right: 0;
				}
			}
		}
	}
