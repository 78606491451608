
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	$dot-active: rgba(71, 107, 179, 0.9);
	$dot-passive: rgba(71, 107, 179, 0.25);

	div#wave {
		position: relative;
		text-align: center;
		width: fit-content;
		margin: 0 auto;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;

		&.absolute {
			position: absolute;
			left: 0;
			right: 0;
			top: 200px;
			margin: auto;
			z-index: $layer-top;
		}

		.dot {
			display: inline-block;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			margin-right: 12px;
			background: $dot-passive;
			animation-name: wave;
			animation-duration: 0.9s;
			animation-timing-function: linear;
			animation-iteration-count: infinite;

			&:nth-child(1) {
				animation-delay: 0.75s;
			}

			&:nth-child(2) {
				animation-delay: 0.95s;
			}

			&:nth-child(3) {
				animation-delay: 1.15s;
			}
		}
	}

	@keyframes wave {
		0%, 60%, 100% {
			transform: initial;
			background: $dot-passive;
		}

		30% {
			transform: translateY(-15px);
			background: $dot-active;
		}
	}
