
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	@import "scss/BookingFinal";

	.booking-errors {
		padding-left: 16px;

		li {
			margin-bottom: 4px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
