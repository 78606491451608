
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.clinic-connections {
		width: 100%;
	}
	.section-header {
		margin-top: 32px;
		margin-bottom: 24px;
	}
