
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.message {
		min-height: 104px;

		pre {
			white-space: pre-wrap;
		}
	}
