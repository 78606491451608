
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	$logo-offset: 76px;

	.virtual-care-banner {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		min-height: 364px;
		background-color: var(--color-neutral-white);
		padding: 32px 16px;

		.logo-constraints {
			max-width: 512px;
		}
		.content {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			max-width: $middle-screen-width;

			.title {
				color: var(--color-neutral-darkest);
				font-size: 32px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.05;
				letter-spacing: normal;
				text-align: center;
			}
			.message {
				color: var(--color-neutral-darkest);
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
				text-align: center;
			}
			.slot-wrapper {
				width: 100%;
				max-width: 344px;
			}
		}
	}
