@font-face {
	font-family: 'BrandFontLight';
	src: url("~@/assets/fonts/Cabin-Regular.ttf");
	font-style: normal;
}

@font-face {
	font-family: 'BrandFontRegular';
	src: url("~@/assets/fonts/Cabin-Regular.ttf");
	font-style: normal;
}

@font-face {
	font-family: "BrandFontMedium";
	src: url("~@/assets/fonts/Cabin-Medium.ttf");
	font-style: normal;
}

@font-face {
	font-family: 'BrandFontBold';
	src: url("~@/assets/fonts/Cabin-SemiBold.ttf");
	font-style: normal;
}

@font-face {
	font-family: 'BrandFontBlack';
	src: url("~@/assets/fonts/Cabin-Bold.ttf");
	font-style: normal;
}

@font-face {
	font-family: 'BrandFontItalic';
	src: url("~@/assets/fonts/Cabin-Italic.ttf");
	font-style: normal;
}

@font-face {
	font-family: 'EuclidCircularA';
	src: url("~@/assets/fonts/euclid-circular-a/Euclid-Circular-A-Regular.woff2");
	font-style: normal;
}

@font-face {
	font-family: 'Marcellus';
	src: url("~@/assets/fonts/Marcellus-Regular.woff2");
	font-style: normal;
}

:root {
	--app-font-regular: BrandFontRegular;
	--app-font-bold: BrandFontBold;
	--app-font-italic: BrandFontItalic;
	--kii-font-regular: EuclidCircularA;
}