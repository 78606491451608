
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.clinic-contact-info {
		font-family: var(--app-font-regular), sans-serif;
		text-align: left;
		color: $color-neutral-darker;
		height: fit-content;
		width: 100%;
		margin-bottom: 16px;

		.clinic-title {
			font-family: var(--app-font-bold), sans-serif;
			font-size: 32px;
			line-height: 1.33;
		}

		.clinic-address {
			font-size: 17px;
			line-height: 1.41;
			white-space: normal;

			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
			}
		}

		.clinic-phone {
			margin-top: 16px;
			font-size: 17px;
			line-height: 1.41;
		}
	}
