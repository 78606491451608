
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.app-button {
		display: block;
		width: 100%;
		height: 72px;
		min-height: 72px;
		border-radius: $border-radius-default;
		transform: scale(1);
		background-color: var(--color-primary-base);
		color: white;
		font-family: var(--app-font-bold), serif;
		font-size: 20px;
		transition: transform 0.3s ease;

		&:disabled {
			opacity: 0.45;
			background-color: var(--color-neutral-light);
		}

		&.neutral {
			background-color: white;
			color: var(--color-neutral-darker);
			border: 1px solid var(--color-neutral-light);
		}

		&:hover {
			filter: brightness(110%);
			&:active {
				transform: scale(0.97);
				filter: brightness(90%);
			}
		}
	}
