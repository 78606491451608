
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.privacy-policy-container {
		header {
			text-align: center;
		}
	}
