
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.appointment-type-icon {
		position: absolute;
		left: 16px;

		font-size: 16px;

		color: var(--color-neutral-white);
		background-color: var(--color-primary-base);

		border-radius: 100%;
	}

	.virtualIcon {
		position: absolute;
		left: 16px;
	}
