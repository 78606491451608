
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.ribbon-container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 100%;
		height: auto;
		background-color: $color-primary-darkest;

		.background-image-container {
			width: 1920px;
			height: 1080px;
			overflow: hidden;
		}
		.svg-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 496px;
			width: 100%;
			background: linear-gradient(180deg, transparent 60%, var(--app-bg) 0);

			#svg-ribbons {
				position: relative;
				width: 100%;

				#Ribbon-Branding {
					fill: var(--color-primary-base);
				}
				#Ribbon-Foreground {
					fill: var(--app-bg);
				}
			}

			/* large screens */
			@media only screen and (max-width: 1920px) {
				height: 496px;
			}
			/* medium screens */
			@media only screen and (max-width: 1440px) {
				height: 372px
			}
			/* medium screens */
			@media only screen and (max-width: 1280px) {
				height: 330px;
			}
			/* small screens */
			@media only screen and (max-width: 1024px) {
				height: 264px;
			}
		}
		// this class just counters the overlap margin to prevent the background image showing through below the ribbons
		.bottom-overlap {
			background-color: var(--app-bg);
			width: 100%;
		}
	}
