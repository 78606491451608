
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.video-controls {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		height: 100%;

		.video-control-section {
			display: flex;
			flex-direction: row;

			flex-grow: 1;
		}

		.control-button-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 32px;

			.control-button {
				height: 64px;
				width: 64px;
				background-color: $color-white-transparent;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				border-radius: 50%;
				box-shadow: 0 0 17px -3px rgba(0,0,0,0.3);

				i {
					font-size: 40px;
					color: white;
					text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
				}

				&.end-call {
					background-color: $color-red-transparent;
					color: white;
				}

				&.call {
					background-color: $color-green-base;
					color: white;
				}
			}
		}

		.call-message {
			flex: 1 0 100%;
			display: flex;
			flex-wrap: wrap;

			overflow: hidden;
			justify-content: center;
			margin-bottom: 8px;
			height: 86px;

			color: $color-neutral-white;
			transition: opacity 4s;

			&:not(.visible) {
				opacity: 0.0;
			}
			&.zero-height {
				height: 0;
			}

			h3 {
				flex: 1 0 100%;
				text-align: center;
			}

			// make the chevron over the call button
			// move up and down.
			@keyframes icon-pointer-move {
				0% {
					transform: translateY(-3px);
				}

				50% {
					transform: translateY(3px);
				}

				100% {
					transform: translateY(-3px);
				}
			}

			i {
				font-size: 32px;
				margin-right: 4px;
				animation: icon-pointer-move 2s infinite ease-in-out;
			}
		}
	}

	.telehealth-icon {
		font-size: 40px;
		color: white;
		text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
	}

	.disabled {
		color: $color-neutral-light-grey;
	}

	@media only screen and (max-width: 768px)
	{
		.video-controls {
			.video-control-section {
				gap: 16px;

				.control-button-container {
					margin: 0;

					.round-button {
						height: 56px;
						width: 56px;
					}
				}
			}
		}
	}

