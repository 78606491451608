
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.privacy-policy-container {
		a {
			color: var(--color-primary-darker);
			font-style: italic;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
				color: var(--color-primary-base);
			}
		}

		header {
			text-align: center;
		}
	}
