
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.button-content {
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
	}
