
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.chat-row {
		position: relative;
		margin-bottom: 16px;
		transition: padding-bottom 0.25s ease;

		--bubble-color: white;
		--time-offset: 24px;

		&.isLast {
			margin-bottom: 32px;
		}

		.received-at {
			transition: opacity 0.25s ease;
			position: absolute;
			right: var(--time-offset);
			bottom: 5px;
			font-size: 13px;
			color: var(--color-neutral-grey);
			opacity: 0;
		}

		&.received {
			.received-at {
				right: auto;
				left: var(--time-offset);
			}
		}

		&.active {
			padding-bottom: 24px;
			--bubble-color: var(--color-neutral-lightest);

			.chat-message.sent {
				--bubble-color: var(--color-primary-dark);
			}

			.received-at {
				opacity: 1;
			}
		}

		.chat-event {
			position: relative;
			color: $color-neutral-grey;
			font-family: var(--app-font-italic), serif;
			font-size: 14px;
			margin: 8px 0;

			.event-time {
				position: absolute;
				left: 4px;
				top: 0;
				bottom: 0;
				margin: auto;
				font-size: 12px;
			}

			.event-details {
				display: block;
				text-align: center;
			}
		}

		.chat-message {
			height: fit-content;
			width: fit-content;
			min-width: 60px;
			max-width: 85%;
			border-radius: 8px;
			background-color: var(--bubble-color);
			border: $border-light-grey;
			color: #334466;
			text-align: left;
			display: flex;
			align-items: center;
			word-break: break-word;
			position: relative;
			z-index: 1;

			span {
				padding: 10px 15px;
			}

			&.received {
				margin-left: 15px;
				margin-right: auto;
				font-family: var(--app-font-regular), serif;

				.typing {
					position: relative;
					left: 2px;
				}

				span {
					width: 100%;
					height: 100%;
					background-color: inherit;
					border-radius: inherit;
				}

				&::before {
					content: '';
					position: absolute;
					z-index: -1;
					width: 0;
					height: 0;
					border: 21px solid transparent;
					border-bottom: 0;
					border-left: 0;
					border-top-color: rgba(124, 129, 140, 0.3);
					left: -3px;
					bottom: -13px;
					transform: rotate(24deg);
				}

				&::after {
					z-index: 1;
					border-left: 0;
					border-top-color: var(--bubble-color);
					left: -2px;
					bottom: -11px;
					transform: rotate(24deg);
				}
			}

			&.sent {
				--bubble-color: var(--color-primary-base);
				background-color: var(--bubble-color);
				color: white;
				font-family: var(--app-font-regular), serif;
				margin-left: auto;
				margin-right: 10px;
				border: none;

				&::after {
					border-right: 0;
					border-top-color: var(--bubble-color);
					right: -2px;
					bottom: -11px;
					transform: rotate(-24deg);
				}
			}

			&::after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				border: 18px solid transparent;
				border-bottom: 0;
			}
		}

		.document-message {
			display: flex;
			flex-direction: column;

			&.sent {
				align-items: end;
			}
			&.received {
				align-items: start;
			}

			.document-label {
				cursor: pointer;
			}

			.document-preview {
				cursor: pointer;
			}
		}
	}
