@media only screen and (min-width: 768px) {
	// default modal size
	ion-modal {
		--width: 376px;

		// applied sizing classes on modal creation
		&.modal-size-large {
			--width: 80%;
			--max-width: 960px;
		}
		// applied sizing classes on modal creation
		&.modal-size-med {
			--width: 80%;
			--max-width: 728px;
		}
		&.modal-size-mobile {
			--width: 80%;
			--max-width: #{$mobile-screen-width};
		}

		&.modal-height-tall {
			--height: 100%;
		}
	}

}