
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.default-popover {
		background-color: var(--color-neutral-white);
		padding: 8px;

		.content {
			background-color: var(--color-neutral-lightest);
			border: solid 1px var(--color-neutral-light);
			border-radius: 8px;
		}

		.icon-delete {
			font-size: 12px;
		}
	}
