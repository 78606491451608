
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.page-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		min-height: calc(100vh - #{$header-total-height});
		padding-bottom: 24px;

		.text {
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: center;
			font-family: var(--kii-font-regular), serif;
		}
	}

	.appointment-loading-spinner {
		color: var(--color-whitelabel-primary-base);
		width: 5em;
		height: 5em;
		margin-bottom: 16px;
		border-radius: 5px;
	}
